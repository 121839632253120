import * as React from 'react';
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	timelineItemClasses,
	TimelineSeparator,
} from '@mui/lab';
import { TimelineType } from '../../../types/common.types';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../theme';

const color = colors();
const themes = theme().typography;
export const HistoryTimeline = ({ logData }: TimelineType) => {
	const classes = useStyles();

	return (
		<Timeline
			sx={{
				[`& .${timelineItemClasses.root}:before`]: {
					flex: 0,
					padding: 0,
				},
			}}
			className={classes.timeline}
		>
			{logData?.map((h, i) => (
				<TimelineItem key={`timeline-${i}`}>
					<TimelineSeparator>
						<TimelineDot />
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						<Typography {...themes.metaText} color={color.neutral[8]}>
							{h.activityDate}
						</Typography>
						{h.logs.map((l, i) => (
							<Typography {...themes.bodyRegular} color={color.text.dark} key={i}>
								{l}
								<br />
							</Typography>
						))}
					</TimelineContent>
				</TimelineItem>
			))}
		</Timeline>
	);
};

const useStyles = makeStyles(() => ({
	timeline: {
		'& .MuiTimeline-root': {
			paddingLeft: '10px',
		},
		'& .MuiTimelineContent-root': {
			paddingLeft: '10px',
		},
		'& .MuiTimelineDot-root': {
			backgroundColor: color.shades.white,
			borderColor: color.neutral[8],
			padding: '2px',
		},
		'& .MuiTimelineConnector-root': {
			backgroundColor: color.neutral[8],
			width: '1px',
		},
	},
}));
