import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, Drawer, IconButton, Typography } from '@mui/material';
import { PanelType } from '../../../types/common.types';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../theme';

export const testid = 'panel';

const color = colors();
const themes = theme().typography;
export const Panel = ({ anchor, open, title, component, onCloseCallback }: PanelType) => {
	const classes = useStyles();

	return (
		<Drawer anchor={anchor} open={open} data-testid={testid}>
			<DialogTitle display={'flex'} alignItems={'center'} className={classes.header} data-testid={`${testid}-title`}>
				<Typography {...themes.h4}>{title}</Typography>
				<IconButton
					onClick={() => onCloseCallback && onCloseCallback(false)}
					size={'small'}
					className={classes.closeIcon}
					data-testid={`${testid}-close`}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			{component}
		</Drawer>
	);
};

const useStyles = makeStyles(() => ({
	header: {
		position: 'sticky',
		top: 0,
		backgroundColor: color.shades.white,
	},
	closeIcon: {
		marginLeft: 'auto !important',
	},
}));
