import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../theme';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from '@mui/material';
import { CustomButton } from '../button/button';
import { ToolTip } from '../tooltip/tooltip';
import { GanttChartType } from '../../../types/common.types';
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';

const color = colors();
const themes = theme().typography;
export const GanttChart = ({ title, chartData, maxCount, handleAddTask, handleEditTask }: GanttChartType) => {
	const classes = useStyles();
	const today = new Date();

	return (
		<div>
			{chartData.length === 0 && (
				<TaskListTable
					chartData={[]}
					title={title}
					maxCount={maxCount}
					handleAddTask={handleAddTask}
					handleEditTask={() => {}}
				/>
			)}
			{chartData.length > 0 && (
				<Gantt
					tasks={chartData}
					viewMode={ViewMode.Month}
					barCornerRadius={10}
					columnWidth={60}
					rowHeight={30}
					fontSize="10px"
					locale={'en-US'}
					todayColor={color.shades.red[10]}
					headerHeight={40}
					viewDate={new Date(`1/1/${today.getFullYear()}`)}
					preStepsCount={3}
					TaskListHeader={({ headerHeight }) => (
						<div
							style={{
								height: headerHeight - 2,
							}}
							className={classes.tableHeader}
						></div>
					)}
					TaskListTable={() => (
						<TaskListTable
							chartData={chartData}
							title={title}
							maxCount={maxCount}
							handleAddTask={handleAddTask}
							handleEditTask={handleEditTask}
						/>
					)}
					TooltipContent={(props) => <TaskTooltip {...props.task} />}
				/>
			)}
		</div>
	);
};

const TaskListTable = ({ title, chartData, maxCount, handleEditTask, handleAddTask }: GanttChartType) => {
	const classes = useStyles();

	return (
		<div className={classes.tableRow}>
			{chartData.map((item, i) => (
				<div className={classes.rowHeader} id={`${title}-${item.id}`}>
					{item.project}
					<div className={classes.iconButton} id="action-buttons">
						<ToolTip
							tooltipText="Edit"
							children={
								<IconButton
									id={`edit-${i}`}
									size="small"
									onClick={() =>
										handleEditTask(
											item.id,
											item.start.toDateString(),
											item.name?.toLowerCase().includes('present') ? '' : item.end.toDateString(),
											title.toLowerCase() === 'position'
										)
									}
								>
									<EditOutlinedIcon fontSize="inherit" />
								</IconButton>
							}
						/>
					</div>
				</div>
			))}
			{chartData.length < maxCount && (
				<CustomButton
					id="new"
					text={title}
					startIcon={<AddIcon sx={{ color: `${color.signal.info}` }} />}
					onClickCallback={handleAddTask}
				/>
			)}
			{chartData.length === maxCount && (
				<CustomButton
					id="new-disabled"
					text={title}
					startIcon={<AddIcon sx={{ color: `${color.text.disabled}` }} />}
					disabled={true}
				/>
			)}
		</div>
	);
};

const TaskTooltip = (task: Task) => {
	const classes = useStyles();

	return (
		<div className={classes.tooltip}>
			<p>{task.project}</p>
			<p>{task.name}</p>
		</div>
	);
};

const useStyles = makeStyles(() => ({
	tableHeader: {
		borderTop: `1px solid ${color.neutral[4]}`,
		borderRight: `1px solid ${color.neutral[4]}`,
		borderBottom: `2px solid ${color.neutral[4]}`,
	},
	tableRow: {
		'& #new': {
			color: color.signal.info,
			border: 'none',
			padding: '0px 10px 0px 0px',
			height: '20px',
		},
		'& #new-disabled': {
			color: color.text.disabled,
			border: 'none',
			padding: '0px',
		},
	},
	rowHeader: {
		...themes.bodyRegular,
		borderRight: `1px solid ${color.neutral[4]}`,
		height: 30,
		width: 185,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: 10,
		'&:hover': {
			backgroundColor: color.neutral[2],
		},
		'&:hover #action-buttons': {
			visibility: 'visible',
		},
	},
	iconButton: {
		paddingRight: '5px',
		visibility: 'hidden',
		'& .MuiIconButton-root': {
			color: color.primary[80],
		},
	},
	tooltip: {
		...themes.metaText,
		backgroundColor: color.text.dark,
		color: color.text.light,
		padding: '10px',
		borderRadius: '10px',
	},
}));
