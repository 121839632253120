import { Alert, Collapse, IconButton } from '@mui/material';
import * as React from 'react';
import { AlertType } from '../../../types/common.types';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useEffect } from 'react';
import Fade from '@mui/material/Fade';

export const testid = 'alert';

export const CustomAlert = ({ open, severity, message, timeout, isFade, onCloseCallback }: AlertType) => {
	useEffect(() => {
		if (timeout && timeout > 0) {
			setTimeout(() => {
				onCloseCallback && onCloseCallback(false);
				open = !open;
			}, timeout);
		}
	}, [timeout]); /* eslint react-hooks/exhaustive-deps: off */

	return (
		<>
			{isFade ? (
				<Fade timeout={2000} in={open} exit={!open}>
					<Alert
						data-testid={testid}
						icon={
							severity === 'error' || severity === 'warning' ? (
								<ErrorOutlineIcon />
							) : severity === 'info' ? (
								<InfoOutlinedIcon />
							) : (
								<DoneAllIcon />
							)
						}
						severity={severity}
						action={
							<IconButton
								data-testid={`${testid}-button`}
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => onCloseCallback && onCloseCallback(false)}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
						sx={{ mb: 2 }}
					>
						{message}
					</Alert>
				</Fade>
			) : (
				<Collapse key="alert-banner" in={open}>
					<Alert
						data-testid={testid}
						icon={
							severity === 'error' || severity === 'warning' ? (
								<ErrorOutlineIcon />
							) : severity === 'info' ? (
								<InfoOutlinedIcon />
							) : (
								<DoneAllIcon />
							)
						}
						severity={severity}
						action={
							<IconButton
								data-testid={`${testid}-button`}
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => onCloseCallback && onCloseCallback(false)}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
						sx={{ mb: 2 }}
					>
						{message}
					</Alert>
				</Collapse>
			)}
		</>
	);
};
