import * as React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateType } from '../../../types/common.types';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../theme';

const color = colors();
const themes = theme().typography;
export const CustomDate = ({
	label,
	labelText,
	width = 120,
	value,
	format,
	disabled,
	hasError,
	disablePast,
	disableToday,
	onChangeCallback,
}: DateType) => {
	const classes = useStyles();
	const today = new Date();

	return (
		<div>
			<div className={classes.label}>{labelText}</div>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					sx={{
						m: 1,
						width: { width },
						'&:hover': {
							'&& fieldset': {
								border: disabled
									? `1px solid ${color.neutral[5]}`
									: hasError
									? `1px solid ${color.shades.red[60]}`
									: `1px solid ${color.shades.blue[50]}`,
							},
						},
						'& .MuiOutlinedInput-notchedOutline': {
							borderColor: hasError ? color.shades.red[60] : color.neutral[4],
						},
					}}
					label={label}
					value={value ? dayjs(value) : null}
					format={format || 'DD MMM YYYY'}
					slotProps={{ textField: { size: 'small', helperText: hasError ? 'This field is required!' : '' } }}
					displayWeekNumber
					showDaysOutsideCurrentMonth
					className={classes.date}
					disabled={disabled}
					disablePast={disablePast}
					minDate={(disableToday && dayjs(today.setDate(today.getDate() + 1))) || null}
					onChange={(newValue) => onChangeCallback && onChangeCallback(dayjs(newValue).format('YYYY-MM-DD'))}
				/>
			</LocalizationProvider>
		</div>
	);
};

const useStyles = makeStyles(() => ({
	date: {
		'& .MuiInputLabel-formControl': {
			...themes.bodyRegular,
			color: color.neutral[5],
		},
		'& .Mui-focused.MuiInputLabel-formControl': {
			color: color.signal.info,
		},
		'& .MuiInputBase-input': {
			...themes.bodyRegular,
			color: color.neutral[8],
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: color.neutral[4],
		},
		'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: `2px solid ${color.signal.info} !important`,
		},
		'& .MuiFormHelperText-root': {
			color: color.shades.red[60],
		},
		'& .MuiButtonBase-root': {
			color: color.primary[100],
		},
	},
	label: {
		...themes.metaText,
		padding: '0px 8px',
		marginBottom: '-5px',
	},
}));
