export const minDate = (dates: string[] | undefined): string => {
	let filteredDates = dates ? dates.filter((d) => d.length > 0) : [];
	return filteredDates.length > 0
		? filteredDates.filter((d) => d.length > 0).reduce((pre, cur) => (Date.parse(pre) < Date.parse(cur) ? pre : cur))
		: '';
};

export const maxDate = (dates: string[] | undefined): string => {
	let filteredDates = dates ? dates.filter((d) => d.length > 0) : [];
	return filteredDates.length > 0
		? filteredDates.filter((d) => d.length > 0).reduce((pre, cur) => (Date.parse(pre) > Date.parse(cur) ? pre : cur))
		: '';
};
