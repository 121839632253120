import { Typography } from '@mui/material';
import * as React from 'react';
import { theme } from '../../../theme';

export const testid = 'header';

export const Header = ({ title }: any) => {
	const themes = theme().typography;

	return (
		<Typography {...themes.h2} data-testid={testid}>
			{title}
		</Typography>
	);
};
