import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { SingleSelectType } from '../../../types/common.types';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../theme';
import { FormHelperText, Select } from '@mui/material';

export const testid = 'single-select';

const color = colors();
const themes = theme().typography;
export const SingleSelectDropDown = ({
	label,
	labelText,
	width = 150,
	data,
	value,
	isTextSelect,
	disabled,
	hasError,
	onSelectCallback,
}: SingleSelectType) => {
	const classes = useStyles();

	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: 48 * 4.5 + 8,
				width: 250,
			},
		},
	};

	return (
		<div>
			<div className={classes.label}>{labelText}</div>
			<FormControl
				sx={{ m: 1, width: { width } }}
				size="small"
				className={classes.dropdown}
				error={hasError}
				data-testid={testid}
			>
				<InputLabel id={`dropdown-input-${label}`} data-testid={`${testid}-label`}>
					{label}
				</InputLabel>
				<Select
					data-testid={`${testid}-dropdown`}
					labelId={`dropdown-${label}`}
					id={`dropdown-${label}`}
					value={value}
					label={label}
					disabled={disabled}
					MenuProps={MenuProps}
					onChange={(e) => onSelectCallback && onSelectCallback(e.target.value)}
					sx={{
						'&:hover': {
							'&& fieldset': {
								border: isTextSelect
									? 'none'
									: disabled
									? `1px solid ${color.neutral[5]}`
									: `1px solid ${color.shades.blue[50]}`,
							},
						},
					}}
				>
					{data?.map((item, i) => (
						<MenuItem value={item.key} key={i} data-testid={`${testid}-item-${i}`}>
							{item.value}
						</MenuItem>
					))}
				</Select>
				{hasError && <FormHelperText data-testid={`${testid}-error`}>This field is required!</FormHelperText>}
			</FormControl>
		</div>
	);
};

const useStyles = makeStyles(() => ({
	dropdown: {
		'& .MuiInputLabel-formControl': {
			...themes.bodyRegular,
			color: color.neutral[5],
		},
		'& .Mui-focused.MuiInputLabel-formControl': {
			color: color.signal.info,
		},
		'& .MuiInputBase-input': {
			...themes.bodyRegular,
			color: color.neutral[8],
		},
		'& .MuiSelect-icon': {
			color: color.neutral[5],
		},
		'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: `2px solid ${color.signal.info} !important`,
		},
		'& .MuiSelect-select': {
			display: 'flex',
		},
		'& .MuiFormHelperText-root': {
			marginLeft: '0px',
		},
	},
	label: {
		...themes.metaText,
		padding: '0px 8px',
		marginBottom: '-5px',
	},
}));
