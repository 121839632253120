import { useState, useEffect, useRef } from "react";

type CallbackOperationCompletedType = (data?: any, error?: string) => void;
const noop: CallbackOperationCompletedType = (): void => {};

export type CallbackOperationType = (...args: any[]) => Promise<any>;

export function useAsyncOperation<TResult>(
  callback: CallbackOperationType,
  completedCallback: CallbackOperationCompletedType = noop
): [Function, boolean, TResult | undefined, string | undefined] {
  const callbackRef: any = useRef();
  const completedCallbackRef: any = useRef();
  const isMountedRef: any = useRef(undefined);
  const [isBusy, setIsBusy] = useState(false);
  const [result, setResult] = useState<TResult | undefined>(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    completedCallbackRef.current = completedCallback;
  }, [completedCallback]);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  async function wrapperFunc(...args: any[]): Promise<void> {
    if (isBusy) {
      throw new Error("Request is in progress");
    }

    try {
      setIsBusy(true);
      setResult(undefined);
      setError(undefined);

      const data: any = await callbackRef.current(...args);

      if (isMountedRef.current) {
        setIsBusy(false);
        setResult(data.data);
        completedCallbackRef.current(data, undefined);
      }
    } catch (err: any) {
      if (isMountedRef.current) {
        setIsBusy(false);
        setError(err.message);
        completedCallbackRef.current(undefined, err.message);
      }
    }
  }

  return [wrapperFunc, isBusy, result, error];
}
