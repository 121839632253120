import * as React from 'react';
import { TooltipType } from '../../../types/common.types';
import { colors, theme } from '../../../theme';
import { Tooltip } from '@mui/material';

export const testid = 'tooltip';

const color = colors();
const themes = theme().typography;
export const ToolTip = ({ width, tooltipText, tooltipColor, tooltipTextColor, children }: TooltipType) => {
	return (
		<Tooltip
			data-testid={testid}
			title={tooltipText}
			arrow
			placement="top"
			componentsProps={{
				tooltip: {
					sx: {
						...themes.bodyRegular,
						bgcolor: tooltipColor || color.shades.black,
						color: tooltipTextColor || color.shades.white,
						'& .MuiTooltip-arrow': {
							color: tooltipColor || color.shades.black,
						},
						width: { width },
					},
				},
			}}
			children={children}
		/>
	);
};
