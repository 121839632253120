import React, { ReactElement, useEffect, useState } from 'react';
import { Header } from '../../components/shared/header/header';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../theme';
import { RoutineType, VerificationRoutineMaster } from '../../types/routine.types';
import { ItemType } from '../../types/common.types';
import { CustomButton } from '../../components/shared/button/button';
import { ToolTip } from '../../components/shared/tooltip/tooltip';
import { CreateRoutinePanel } from '../../components/verificationRoutine/create';
import { DocCertDialog } from '../../components/verificationRoutine/docCertDialog';
import { Loader } from '../../components/shared/loader/loader';
import { CustomAlert } from '../../components/shared/alert/alert';
import { msalInstance } from '../../config/msalConfig';
import { useAsyncOperation } from '../../utils/use-async-operation';
import { getRoutines, getVerRoutineMaster } from '../../services/verification-routine-service';

export const testid = 'verification-routine';

const color = colors();
const themes = theme().typography;

export const VerificationRoutine = (): ReactElement => {
	const classes = useStyles();
	const userAccount = msalInstance.getActiveAccount();
	const loggedInUser = userAccount ? `${userAccount.name?.split('(')[0]}| ${userAccount.username}` : '';

	const [documentList, setDocumentList] = useState<ItemType[]>([]);
	const [scopeCertList, setScopeCertList] = useState<ItemType[]>([]);
	const [routine, setRoutine] = useState<RoutineType>({} as RoutineType);
	const [isCreate, setIsCreate] = useState<boolean>(false);
	const [isDoc, setIsDoc] = useState<boolean>(false);
	const [openCreate, setOpenCreate] = useState<boolean>(false);
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [openError, setOpenError] = useState(false);
	const [openSuccess, setOpenSuccess] = useState(false);

	const [getVerRoutines, verRoutineBusy, verRoutines, verRoutineError] = useAsyncOperation<RoutineType[]>(getRoutines);
	const [getMasterData, masterBusy, masterData, masterError] =
		useAsyncOperation<VerificationRoutineMaster>(getVerRoutineMaster);

	useEffect(() => {
		getMasterData();
		getVerRoutines();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (verRoutineError || masterError) setOpenError(true);
	}, [verRoutineError, masterError]);

	useEffect(() => {
		if (masterData) {
			setDocumentList([
				...masterData.documentMaster.map((d) => {
					return { key: d.keyId, value: d.name };
				}),
				{
					key: -1,
					value: (
						<div className={classes.newDoc}>
							<AddIcon />
							Add a document
						</div>
					),
				},
			]);
			setScopeCertList([
				...masterData.scopeCertificates.map((d) => {
					return { key: d.certificateId, value: d.name };
				}),
			]);
		}
	}, [masterData]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleAdd = (index: number, isDoc: boolean) => {
		if (verRoutines) setRoutine({ ...verRoutines.filter((_, i) => i === index)[0], updatedBy: loggedInUser });
		setIsDoc(isDoc);
		setOpenDialog(true);
	};

	const handleUpdate = (isNewDoc: boolean) => {
		if (openCreate) setOpenCreate(false);
		else setOpenDialog(false);
		getVerRoutines();
		if (isNewDoc) getMasterData();
		setOpenSuccess(true);
	};

	return (
		<>
			<Loader key={`${testid}-loader`} open={verRoutineBusy || masterBusy} message="Loading..." />
			<CustomAlert
				open={openError}
				message="Something went wrong while retrieving verification routines data!"
				severity="error"
				timeout={5000}
				isFade={false}
				onCloseCallback={() => {
					setOpenError(false);
				}}
			/>
			{openCreate ? (
				<CreateRoutinePanel
					routineNames={verRoutines?.map((r) => r.routineName.toLowerCase()) || []}
					certificateList={scopeCertList}
					documentList={documentList}
					loggedInUser={loggedInUser}
					onBackClick={setOpenCreate}
					onSaveClick={handleUpdate}
					data-testid={`${testid}-create`}
				/>
			) : (
				<>
					<Header title="Verification Routines" data-testid={`${testid}-title`} />
					<div className={classes.button} data-testid={`${testid}-create-button`}>
						<CustomButton
							id="new"
							text="Create a new routine"
							width={180}
							variant="contained"
							onClickCallback={() => {
								setOpenCreate(true);
								setIsCreate(true);
							}}
						/>
					</div>
					<TableContainer className={classes.table} data-testid={`${testid}-body`}>
						<Table stickyHeader>
							<TableHead>
								<TableRow key={`${testid}-header`}>
									<TableCell width="25%">Routine Name</TableCell>
									<TableCell width="44%">Required Document</TableCell>
									<TableCell width="5%">Type</TableCell>
									<TableCell width="15%">Deadline</TableCell>
									<TableCell width="1%" />
								</TableRow>
							</TableHead>
							{verRoutines && (
								<TableBody data-testid={`${testid}-body`}>
									{verRoutines.map((row, i) => (
										<TableRow hover key={`${testid}-${row.id}`} data-testid={`${testid}-${row.id}`}>
											<TableCell>
												<div className={classes.routine}>
													{row.routineName}
													{row.scopeCertificates && row.scopeCertificates.length > 0 ? (
														<ToolTip
															tooltipText={
																<>
																	<b>Scope Certificate</b>
																	{row.scopeCertificates.map((s) => (
																		<p className={classes.flexDisplay} key={s.keyId}>
																			<div style={{ width: '15%' }}>{s.keyId}</div>
																			<div>{s.name}</div>
																		</p>
																	))}
																</>
															}
															width={300}
															children={
																<span className={classes.centerAlign}>
																	<VerifiedIcon className={classes.iconButton} id="scope-cert" />
																</span>
															}
														/>
													) : (
														<ToolTip
															tooltipText="Add scope certificate"
															children={
																<span className={classes.centerAlign}>
																	<VerifiedOutlinedIcon
																		id="add-sc"
																		className={classes.iconButton}
																		onClick={() => handleAdd(i, false)}
																	/>
																</span>
															}
														/>
													)}
												</div>
											</TableCell>
											<TableCell>{row.documents.map((d) => d.name).join(', ')}</TableCell>
											<TableCell>{row.type}</TableCell>
											<TableCell>{row.deadline}</TableCell>
											<TableCell>
												<ToolTip
													tooltipText="Add document"
													children={
														<span>
															<IconButton id="add-doc" onClick={() => handleAdd(i, true)}>
																<AddIcon className={classes.iconButton} />
															</IconButton>
														</span>
													}
												/>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							)}
						</Table>
					</TableContainer>
				</>
			)}
			{openDialog && (
				<DocCertDialog
					isDoc={isDoc}
					data={routine}
					list={isDoc ? documentList : scopeCertList}
					onSaveClick={handleUpdate}
					onCancelClick={() => setOpenDialog(false)}
				/>
			)}
			{openSuccess && (
				<div className={classes.successAlert}>
					<CustomAlert
						open={openSuccess}
						timeout={5000}
						message={`${isCreate ? 'Routine' : isDoc ? 'Document' : 'Scope Certificate'} saved successfully!`}
						severity="success"
						onCloseCallback={() => {
							setOpenSuccess(false);
							setIsCreate(false);
						}}
					/>
				</div>
			)}
		</>
	);
};

const useStyles = makeStyles(() => ({
	flexDisplay: {
		display: 'flex',
		alignItems: 'center',
	},
	centerAlign: {
		verticalAlign: 'text-top',
	},
	button: {
		display: 'flex',
		justifyContent: 'end',
		'& #new': {
			backgroundColor: color.primary[100],
			color: color.text.light,
		},
	},
	table: {
		height: 'calc(100% - 80px)',
		'& .MuiTableCell-head': {
			...themes.bodyMedium,
			color: color.primary[100],
			borderTop: `1px solid ${color.neutral[2]}`,
		},
		'& .MuiTableCell-body': {
			...themes.bodyRegular,
		},
		'& .MuiTableCell-root': {
			borderBottom: `1px solid ${color.neutral[2]}`,
			padding: '13px 8px',
		},
		'& .MuiTableRow-hover': {
			'&:hover': {
				backgroundColor: `${color.signal.infoLight} !important`,
				'& #add-sc': {
					display: 'block',
				},
			},
		},
	},
	routine: {
		display: 'ruby-text',
		'& svg': {
			marginLeft: '5px',
		},
		'& #add-sc': {
			display: 'none',
			cursor: 'pointer',
		},
	},
	iconButton: {
		width: '16px !important',
		height: '16px !important',
		color: color.primary[90],
	},
	newDoc: {
		display: 'flex',
		width: '100%',
		color: color.signal.info,
		borderTop: `1px solid ${color.neutral[4]}`,
		paddingTop: '10px',
	},
	successAlert: {
		position: 'fixed',
		top: '10px',
		left: '50%',
		transform: 'translateX(-50%)',
	},
}));
