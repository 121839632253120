import { PostResponse, SupplierFilter, SupplierInfoResponse, SupplierMaster, SupplierUpsertType } from '../types/supplier.types';
import axiosInstance from '../utils/axios-instance';

// #region GET
export const getSupplierInfoApi = async (filter: SupplierFilter): Promise<SupplierInfoResponse> => {
	const url: string = `/supplier/getSupplierInfo`;
	return axiosInstance.post(url, filter);
};

export const getTTMaster = async (): Promise<SupplierMaster> => {
	const url: string = `/supplier/getTTMasters`;
	return axiosInstance.get(url);
};
// #endregion

// #region POST
export const saveSupplierInfoApi = async (supplierUpdateRequests: SupplierUpsertType[]): Promise<PostResponse> => {
	const url: string = `/supplier/saveSupplierInfo`;
	return await axiosInstance.post(url, supplierUpdateRequests);
};
// #endregion
