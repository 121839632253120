import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { useMsalAuthentication } from '@azure/msal-react';
import { loginRequest, msalInstance } from '../../config/msalConfig';
import * as React from 'react';
import { useEffect } from 'react';

const Authenticate = ({ children }) => {
	const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);
	const account = msalInstance.getActiveAccount();
	const accounts = msalInstance.getAllAccounts();
	// Default to using the first account if no account is active on page load
	if (!account && accounts.length > 0) {
		// Account selection logic is app dependent. Adjust as needed for different use cases.
		msalInstance.setActiveAccount(accounts[0]);
	}

	useEffect(() => {
		if (error instanceof InteractionRequiredAuthError) {
			login(InteractionType.Redirect, loginRequest);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);

	return <>{children}</>;
};
export default Authenticate;
