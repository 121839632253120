import React, { ReactElement } from 'react';
import { TabType } from '../../../types/common.types';
import { Box, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../theme';

export const testid = 'tab';

const color = colors();
export const CustomTab = ({ data, value, onChangeCallback }: TabType): ReactElement => {
	const classes = useStyles();
	const themes = theme().typography;

	return (
		<Box
			sx={{
				borderBottom: 1,
				borderBottomColor: `${color.neutral[2]} !important`,
				borderColor: 'divider',
				width: '100%',
			}}
			data-testid={testid}
		>
			<Tabs
				value={value}
				onChange={(e, i) => onChangeCallback && onChangeCallback(i)}
				className={classes.tab}
				data-testid={`${testid}-tabs`}
			>
				{data?.map((t, i) => {
					return (
						<Tab
							label={t.value}
							value={t.key}
							key={i}
							style={{ ...themes.bodyMedium }}
							data-testid={`${testid}-${i}`}
						/>
					);
				})}
			</Tabs>
		</Box>
	);
};

const useStyles = makeStyles(() => ({
	tab: {
		'& .MuiTabs-indicator': {
			height: '4px',
			backgroundColor: color.signal.info,
		},
		'& .MuiButtonBase-root': {
			textTransform: 'capitalize',
			color: color.neutral[8],
		},
		'& .MuiButtonBase-root.Mui-selected': {
			color: color.primary[100],
		},
	},
}));
