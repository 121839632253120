import React, { ReactElement, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { ItemType } from '../../types/common.types';
import { CustomTab } from '../../components/shared/tab/tab';
import { Header } from '../../components/shared/header/header';
import { MaterialPlatform } from '../materialPlatform/materialPlatform';
import { MaterialRoutine } from '../ruleEngine/materialRoutine';

export const Material = (): ReactElement => {
	const classes = useStyles();
	const tabs: ItemType[] = [
		{
			key: 0,
			value: 'Platform Enrollment',
		},
		{
			key: 1,
			value: 'Material Integrity',
		},
	];

	const [activeTab, setActiveTab] = useState<number>(0);

	return (
		<>
			<Header title="Materials" />
			<div className={classes.topPadding}>
				<CustomTab data={tabs} value={activeTab} onChangeCallback={setActiveTab} />
			</div>
			{activeTab === 0 ? <MaterialPlatform /> : <MaterialRoutine />}
		</>
	);
};

const useStyles = makeStyles(() => ({
	topPadding: {
		paddingTop: '15px',
	},
}));
