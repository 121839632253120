import { PostResponse, RoutineType, VerificationRoutineMaster } from 'src/types/routine.types';
import axiosInstance from '../utils/axios-instance';

// #region GET
export const getRoutines = async (): Promise<RoutineType[]> => {
	const url: string = `/VerificationRoutine/GetRoutine`;
	return axiosInstance.get(url);
};

export const getVerRoutineMaster = async (): Promise<VerificationRoutineMaster> => {
	const url: string = `/VerificationRoutine/GetMaster`;
	return axiosInstance.get(url);
};
// #endregion

// #region POST
export const saveVerificationRoutine = async (request: RoutineType): Promise<PostResponse> => {
	const url: string = `/VerificationRoutine/SaveVerificationRoutine`;
	return await axiosInstance.post(url, request);
};
// #endregion
