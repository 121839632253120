import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { msalInstance } from '../../config/msalConfig';
import * as React from 'react';
import Authenticate from './authenticate';

/**
 * 1. sets active account instance
 * 2. handles events relating to account activation
 * @returns
 */
export default function AuthWrapper({ children }) {
	// Optional - This will update account state if a user signs in from another tab or window
	msalInstance.enableAccountStorageEvents();

	return (
		<MsalProvider instance={msalInstance}>
			<Authenticate>
				<AuthenticatedTemplate>{children}</AuthenticatedTemplate>
				<UnauthenticatedTemplate>Signing you in, please wait...</UnauthenticatedTemplate>
			</Authenticate>
		</MsalProvider>
	);
}
