import React, { ReactElement, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { Chip } from '@mui/material';
import { colors, theme } from '../../theme';
import { DocumentCertificateType, PostResponse } from '../../types/routine.types';
import { ListType } from '../../types/routine.types';
import { SingleSelectDropDown } from '../shared/dropdown/singleSelect';
import { TextBox } from '../shared/textbox/textbox';
import { CustomAlert } from '../shared/alert/alert';
import { CustomDialog } from '../shared/dialog/dialog';
import { Loader } from '../shared/loader/loader';
import { useAsyncOperation } from '../../utils/use-async-operation';
import { saveVerificationRoutine } from '../../services/verification-routine-service';
import { ItemType } from '../../types/common.types';

export const testid = 'doc-cert';

const color = colors();
const themes = theme().typography;

export const DocCertDialog = ({
	isDoc,
	data,
	list,
	onSaveClick,
	onCancelClick,
}: DocumentCertificateType): ReactElement => {
	const classes = useStyles();

	const [item, setItem] = useState<ListType[]>([]);
	const [isNew, setIsNew] = useState<boolean>(false);
	const [docName, setDocName] = useState<string>('');
	const [error, setError] = useState<string>('');
	const [listId, setListId] = useState<number[]>(
		isDoc ? data.documents.map((l) => l.keyId) : data.scopeCertificates?.map((l) => l.keyId) || []
	);
	const [filteredList, setFilteredList] = useState<ItemType[]>([]);

	const [saveRoutine, saveBusy, saveData, saveError] = useAsyncOperation<PostResponse>(saveVerificationRoutine);

	useEffect(() => {
		setFilteredList(list.filter((item) => !listId.includes(item.key)));
	}, [listId]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (saveData) onSaveClick(item.filter((i) => i.keyId === -1).length > 0);
		if (saveError) setError('Error saving routine. Please try again.');
	}, [saveData, saveError]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleItem = (value: string) => {
		setError('');
		if (parseInt(value) === -1) setIsNew(true);
		else {
			const selectedValue: ListType = {
				keyId: parseInt(value),
				name: list.filter((s) => s.key === parseInt(value))[0]?.value?.toString() || '',
			};
			setItem([...item, selectedValue]);

			let newIds = [...listId, parseInt(value)];
			setListId(newIds);
		}
	};

	const removeItem = (id: number) => {
		setItem(item.filter((r) => r.keyId !== id));
		setListId(listId.filter((l) => l !== id));
	};

	const handleNewDoc = () => {
		setIsNew(false);
		if (list.filter((s) => s.value?.toString().toLowerCase() === docName.toLowerCase()).length > 0)
			setError(`Document - '${docName}' already exists in master list!`);
		else setItem([...item, { keyId: -1, name: docName }]);
		setDocName('');
	};

	const handleSave = () => {
		let isValid: string = '';
		if (isDoc) {
			setError('');
			let docs = data.documents ? [...data.documents, ...item] : item;
			if (docs.length > 4) isValid = 'A maximum of four documents are allowed.';
			if (isValid.length > 0) setError(isValid);
			else data.documents = data.documents ? [...data.documents, ...item] : item;
		} else {
			setError('');
			if (item.length > 3) isValid = 'A maximum of three scope certificaes are allowed.';
			if (isValid.length > 0) setError(isValid);
			else data.scopeCertificates = item;
		}

		if (!isValid.length) saveRoutine(data);
	};

	return (
		<>
			<Loader key={`${testid}-loader`} open={saveBusy} message="Saving Routine..." />
			<CustomDialog
				key={testid}
				open={true}
				title={isDoc ? 'Add a document' : 'Add scope certificate'}
				yesButtonText="Add"
				noButtonText="Cancel"
				onYesClickCallback={isNew ? handleNewDoc : handleSave}
				onNoClickCallback={() => (isNew ? setIsNew(false) : onCancelClick())}
				yesButtonDisable={isNew ? docName.length === 0 : item.length === 0}
				onCloseCallback={onCancelClick}
				component={
					<>
						{!isNew && (
							<div className={classes.label}>
								{`Please select the ${
									isDoc ? 'document(s)' : 'scope certificate(s)'
								} you would like to add to routine `}
								<b>{data.routineName}</b>.
							</div>
						)}
						<br />

						<div className={classes.alert}>
							<CustomAlert
								open={error.length > 0}
								message={error}
								severity="error"
								onCloseCallback={() => {
									setError('');
								}}
							/>
						</div>

						<div className={classes.label}>
							{isNew ? 'Document name' : isDoc ? 'Select document' : 'Select scope certificate'}
							<span style={{ color: color.signal.error }}>*</span>
						</div>
						{!isNew && (
							<>
								<SingleSelectDropDown
									label=""
									data={filteredList}
									value=""
									width={348}
									onSelectCallback={(e) => handleItem(e)}
								/>
								{item.length > 0 &&
									item.map((row, i) => (
										<>
											<Chip
												key={`${testid}-chip-${i}`}
												label={`${!isDoc ? row.keyId : ''} ${row.name}`}
												deleteIcon={<CloseIcon data-testid={`${testid}-delete-${i}`} />}
												className={classes.chip}
												onDelete={() => removeItem(row.keyId)}
												data-testid={`${testid}-chip-${i}`}
											/>
										</>
									))}
							</>
						)}
						{isNew && <TextBox id={`${testid}-doc-name`} label="" onChangeCallback={setDocName} width={348} />}
					</>
				}
			/>
		</>
	);
};

const useStyles = makeStyles(() => ({
	chip: {
		width: 348,
		borderRadius: '0px !important',
		margin: '1px 8px !important',
		justifyContent: 'space-between !important',
		backgroundColor: `${color.shades.blue[10]} !important`,
		'& svg': {
			color: `${color.primary[90]} !important`,
		},
	},
	label: {
		...themes.metaText,
		padding: '0px 8px',
		marginBottom: '-5px',
	},
	alert: {
		'& .MuiAlert-standard': {
			...themes.metaText,
			width: 330,
			border: `1px solid ${color.shades.red[70]}`,
			color: color.shades.red[10],
		},
		'& .MuiAlert-icon': {
			alignItems: 'center',
			color: `${color.shades.red[70]} !important`,
		},
		'& .MuiAlert-message': {
			color: color.shades.red[70],
		},
		'& .MuiAlert-action': {
			alignItems: 'center',
			color: color.shades.red[70],
		},
	},
}));
