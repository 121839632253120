import { Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import {
	PlatformType,
	PostResponse,
	SupplierEditType,
	SupplierType,
	SupplierUpsertType,
} from '../../types/supplier.types';
import { colors, theme } from '../../theme';
import { SingleSelectDropDown } from '../../components/shared/dropdown/singleSelect';
import { CustomDate } from '../../components/shared/date/date';
import { TextBox } from '../../components/shared/textbox/textbox';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { CustomButton } from '../../components/shared/button/button';
import { HistoryTimeline } from '../../components/shared/timeline/timeline';
import { ItemType } from '../../types/common.types';
import { msalInstance } from '../../config/msalConfig';
import { ToolTip } from '../../components/shared/tooltip/tooltip';
import { useAsyncOperation } from '../../utils/use-async-operation';
import { saveSupplierInfoApi } from '../../services/supplier-service';
import { Loader } from '../../components/shared/loader/loader';
import { CustomAlert } from '../../components/shared/alert/alert';

const color = colors();
const themes = theme().typography;
export const SupplierEditPage = ({
	rows,
	isBulk,
	supplierPlatforms,
	supplierScopeStatuses,
	isSave,
}: SupplierEditType) => {
	const classes = useStyles();
	const supplier: SupplierType = rows[0];
	const [platforms, setPlatforms] = useState<PlatformType[]>(supplier.platforms || [{} as PlatformType]);
	const [platformContact, setPlatformContact] = useState<string[][]>(platforms.map((p) => p.contacts || []));
	const [platformName, setPlatformName] = useState<string[]>(platforms.map((p) => p.tpId?.toString() || ''));
	const [platformNameError, setPlatformNameError] = useState<boolean[]>([]);
	const [platformStatus, setPlatformStatus] = useState<string[]>(
		platforms.map((p) => p.scopeStatusId?.toString() || '')
	);
	const [platformStatusError, setPlatformStatusError] = useState<boolean[]>([]);
	const [platformDate, setPlatformDate] = useState<string[]>(platforms.map((p) => p.scopeStartDate || ''));
	const [platformDateError, setPlatformDateError] = useState<boolean[]>([]);
	const [platformComments, setPlatformComments] = useState<string[]>(platforms.map((p) => p.comments || ''));
	const [platformList, setPlatformList] = useState<ItemType[]>([]);
	const [isSameStatus, setIsSameStatus] = useState<boolean>(false);
	const [isSameDate, setIsSameDate] = useState<boolean>(false);
	const [startPageLoad, setStartPageLoad] = useState(false);
	const [errorAlertOpen, setErrorAlertOpen] = useState(false);

	const [saveSupplierInfo, , saveResponse, saveError] = useAsyncOperation<PostResponse>(saveSupplierInfoApi);

	useEffect(() => {
		const pfList: ItemType[] = [];
		supplierPlatforms
			?.filter((x) => x.key !== 0)
			.map((p) =>
				pfList.push({
					key: p.key,
					value: (
						<>
							<img
								src={require(`../../images/${p.value.toLocaleLowerCase().replaceAll(' ', '')}.png`)}
								alt={p.value}
								width="24px"
								height="24px"
							/>
							{p.value}
						</>
					),
				})
			);
		setPlatformList([...pfList]);

		let status = rows.map((row) => row.platform?.scopeStatusId);
		setIsSameStatus(new Set(status).size === 1);
		let date = rows.map((row) => row.platform?.scopeStartDate);
		setIsSameDate(new Set(date).size === 1);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (saveError) {
			setStartPageLoad(false);
			setErrorAlertOpen(true);
		}
	}, [saveError]);

	useEffect(() => {
		if (saveResponse) {
			isSave && isSave(true);
		}
	}, [saveResponse]); // eslint-disable-line react-hooks/exhaustive-deps

	const onDiscardClick = useCallback(() => {
		setPlatforms(rows[0].platforms || []);
		setPlatformContact(platforms.map((p) => p.contacts || []));
		setPlatformName(platforms.map((p) => p.tpId?.toString() || ''));
		setPlatformStatus(platforms.map((p) => p.scopeStatusId?.toString() || ''));
		setPlatformDate(platforms.map((p) => p.scopeStartDate || ''));
		setPlatformComments(platforms.map((p) => p.comments || ''));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onSaveClick = () => {
		setPlatformNameError(platformName.map((p) => p.length <= 0));
		setPlatformStatusError(platformStatus.map((s) => s.length <= 0));
		setPlatformDateError(platformDate.map((s, i) => s.length <= 0 && platformStatus[i].toString() === '1'));

		let isFormValid =
			platformName.filter((p) => p.length <= 0).length === 0 &&
			platformStatus.filter((s) => s.length <= 0).length === 0 &&
			platformDate.filter((s, i) => s.length <= 0 && platformStatus[i].toString() === '1').length === 0;

		if (isFormValid) {
			setStartPageLoad(true);
			let saveData: SupplierUpsertType[] = [];
			const platformsData: PlatformType[] = [];
			const loggedInUser = msalInstance.getActiveAccount();
			platforms.map((_, i) =>
				platformsData.push({
					tpId: parseInt(platformName[i]),
					tpName: supplierPlatforms?.filter((p) => p.key === parseInt(platformName[i]))[0].value,
					scopeStatusId: parseInt(platformStatus[i]),
					scopeStatus: supplierScopeStatuses?.filter((s) => s.key === parseInt(platformStatus[i]))[0].value,
					scopeStartDate: platformDate[i] === '' ? undefined : platformDate[i],
					comments: platformComments[i],
					contacts: platformContact[i],
				})
			);
			rows.forEach((r, i) => {
				saveData.push({
					supplierId: r.supplierId,
					platforms: [...platformsData],
					user: {
						loggedInUserName: (loggedInUser && loggedInUser.name?.split('(')[0])?.trim() || '',
						loggedInUserEmail: (loggedInUser && loggedInUser.username) || '',
					},
				});
				if (isBulk && !isSameStatus) {
					saveData[i].platforms.forEach((s, j) => {
						if (r.platforms && r.platforms.length > j) {
							s.scopeStatusId = r.platforms[j].scopeStatusId;
							s.scopeStatus = r.platforms[j].scopeStatus;
						}
						saveData[i].platforms[j] = { ...saveData[i].platforms[j] };
					});
				}
				if (isBulk && !isSameDate) {
					saveData[i].platforms.forEach((s, j) => {
						if (r.platforms && r.platforms.length > j) s.scopeStartDate = r.platforms[j].scopeStartDate;
						saveData[i].platforms[j] = { ...saveData[i].platforms[j] };
					});
				}
			});
			saveSupplierInfo(saveData);
		}
	};

	const addPlatform = () => {
		setPlatforms(() => {
			return [...platforms, { contact: [], scopeStartDate: undefined } as PlatformType];
		});
		setPlatformName([...platformName, '']);
		setPlatformStatus([...platformStatus, '']);
		setPlatformDate([...platformDate, '']);
		addPlatformContact(platforms.length);
	};

	let removePlatform = (pfIndex: number) => {
		setPlatformContact((p) => p.filter((_, index) => index !== pfIndex));
		setPlatformName((p) => p.filter((_, index) => index !== pfIndex));
		setPlatformStatus((p) => p.filter((_, index) => index !== pfIndex));
		setPlatformDate((p) => p.filter((_, index) => index !== pfIndex));
		setPlatformComments((p) => p.filter((_, index) => index !== pfIndex));
		setPlatforms((p) => p.filter((_, index) => index !== pfIndex));
	};

	const addPlatformContact = (pfIndex: number) => {
		let contacts: string[] = [...(platformContact[pfIndex] || [])];
		setPlatformContact((s) => {
			s[pfIndex] = [...contacts, ''];
			return [...s];
		});
	};

	let removePlatformContact = (pfIndex: number, contactIndex: number) => {
		let contacts: string[] = [...(platformContact[pfIndex] || [])];
		contacts.splice(contactIndex, 1);
		setPlatformContact((s) => {
			s[pfIndex] = [...contacts];
			return [...s];
		});
	};

	const handleContactInput = (pfIndex: number, ctIndex: number, value: string) => {
		let contacts: string[] = [...(platformContact[pfIndex] || [])];
		contacts[ctIndex] = value;
		setPlatformContact((s) => {
			s[pfIndex] = [...contacts];
			return [...s];
		});
	};

	return (
		<>
			<CustomAlert
				open={errorAlertOpen}
				message="Request could not be completed!"
				severity="error"
				onCloseCallback={() => {
					setErrorAlertOpen(false);
				}}
			/>

			<Loader key="edit-loader" open={startPageLoad} message="Save in progress..." />

			<div className={classes.supplierEdit} key="supplier-edit-page">
				{supplier && (
					<Grid key={supplier.id} container spacing={2} {...themes.bodyRegular}>
						{!isBulk && (
							<>
								<Grid item xs={2} color={color.neutral[8]}>
									SPUR ID
								</Grid>
								<Grid item xs={10} color={color.text.dark}>
									{supplier.supplierId}
								</Grid>
								<Grid item xs={2} color={color.neutral[8]}>
									Supplier code
								</Grid>
								<Grid item xs={10} color={color.text.dark}>
									{supplier.supplierCode}
								</Grid>
								<Grid item xs={2} color={color.neutral[8]}>
									SPUR status
								</Grid>
								<Grid item xs={10} color={color.text.dark}>
									{supplier.supplierStatus}
								</Grid>
								<Grid item xs={2} color={color.neutral[8]}>
									Supplier type
								</Grid>
								<Grid item xs={10} color={color.text.dark}>
									{supplier.supplierTypes}
								</Grid>
								<Grid item xs={2} color={color.neutral[8]}>
									Address
								</Grid>
								<Grid item xs={10} color={color.text.dark}>
									{supplier.address}
								</Grid>
								<Grid item xs={2} color={color.neutral[8]}>
									Contact
								</Grid>
								<Grid item xs={10} color={color.text.dark}>
									{supplier.contact}
								</Grid>
								<Grid item xs={2} color={color.neutral[8]}>
									Bounced order count
								</Grid>
								<Grid item xs={10} color={color.text.dark}>
									{supplier.bouncedOrderCount}
								</Grid>
								<Grid item xs={2} color={color.neutral[8]}>
									Platform
								</Grid>
								<Grid item xs={10}>
									<>
										{platforms &&
											platforms.map((_, pindex) => (
												<div key={`platform-${pindex}`}>
													<div className={classes.divider} key={`platform-header-${pindex}`}>
														<Typography {...themes.bodyMedium}>{`Platform ${pindex + 1}`}</Typography>
														<Divider id="single-edit-divider" />
														{platforms.length > 1 && <DeleteOutlineIcon onClick={() => removePlatform(pindex)} />}
													</div>
													{platformList && (
														<SingleSelectDropDown
															label="Select Platform"
															data={platformList}
															value={platformName[pindex]}
															width={476}
															hasError={platformNameError[pindex]}
															onSelectCallback={(e) => {
																setPlatformName((p) => {
																	p[pindex] = e;
																	return [...p];
																});
																setPlatformNameError(platformName.map((p) => p.length <= 0));
															}}
														/>
													)}
													<SingleSelectDropDown
														label="Scope Status"
														data={supplierScopeStatuses}
														value={platformStatus[pindex]}
														width={230}
														hasError={platformStatusError[pindex]}
														onSelectCallback={(e) => {
															setPlatformStatus((p) => {
																p[pindex] = e;
																return [...p];
															});
															setPlatformStatusError(platformStatus.map((s) => s.length <= 0));
															setPlatformDateError([]);
														}}
													/>
													<CustomDate
														label="Scope Start Date"
														value={platformDate[pindex]}
														width={230}
														disabled={platformStatus[pindex].toString() !== '1'}
														hasError={platformDateError[pindex]}
														onChangeCallback={(e) => {
															setPlatformDate((p) => {
																p[pindex] = e;
																return [...p];
															});
															setPlatformDateError([]);
														}}
													/>
													{platformContact[pindex].length > 0 &&
														platformContact[pindex].map((contact, cindex) => (
															<div className={classes.platformContact} key={`contact-${pindex}-${cindex}`}>
																<TextBox
																	id={`${pindex}-${cindex}`}
																	label="Email Address"
																	width={433}
																	value={contact || ''}
																	onChangeCallback={(e) => handleContactInput(pindex, cindex, e)}
																/>
																{cindex + 1 < (platformContact[pindex].length || 0) ? (
																	<DeleteOutlineIcon onClick={() => removePlatformContact(pindex, cindex)} />
																) : (
																	<AddCircleOutlineIcon onClick={() => addPlatformContact(pindex)} />
																)}
															</div>
														))}
													{platformContact[pindex].length === 0 && (
														<div className={classes.platformContact} key={`contact-${pindex}-0`}>
															<TextBox
																id={`${pindex}-0`}
																label="Email Address"
																width={440}
																onChangeCallback={(e) => handleContactInput(pindex, 0, e)}
															/>
															<AddCircleOutlineIcon onClick={() => addPlatformContact(pindex)} />
														</div>
													)}
													<TextBox
														id={0}
														label="Leave comments here"
														value={platformComments[pindex]}
														width={476}
														isMultiline
														onChangeCallback={(e) =>
															setPlatformComments((p) => {
																p[pindex] = e;
																return [...p];
															})
														}
													/>
												</div>
											))}
										<CustomButton
											id="new-platform"
											text="New Platform"
											startIcon={<AddIcon sx={{ color: `${color.signal.info}` }} />}
											onClickCallback={addPlatform}
										/>
									</>
								</Grid>
								{supplier?.activityLog && (
									<>
										<Grid item xs={2} color={color.neutral[8]}>
											History
										</Grid>
										<Grid item xs={10} color={color.text.dark}>
											<HistoryTimeline key="platform-history" logData={supplier.activityLog} />
										</Grid>
									</>
								)}
							</>
						)}
						{isBulk && (
							<>
								<Grid item xs={2} color={color.neutral[8]}>
									Suppliers
								</Grid>
								<Grid item xs={10} color={color.text.dark}>
									{rows.map((r) => (
										<>
											{r.supplierName}
											<br />
										</>
									))}
								</Grid>
								<Grid item xs={2} color={color.neutral[8]}>
									Platform
								</Grid>
								<Grid item xs={10} color={color.text.dark}>
									<>
										{platforms &&
											platforms.map((_, pindex) => (
												<div key={`platform-${pindex}`}>
													{(!rows[0].platforms || (rows[0].platforms && pindex >= rows[0].platforms.length)) && (
														<div className={classes.divider} key={`platform-header-${pindex}`}>
															<Typography {...themes.bodyMedium}>{`Platform ${pindex + 1}`}</Typography>
															<Divider id="single-edit-divider" />
															{platforms.length > 1 && <DeleteOutlineIcon onClick={() => removePlatform(pindex)} />}
														</div>
													)}
													{rows[0].platforms && rows[0].platforms.length > pindex && (
														<div className={classes.divider} key={`platform-header-${pindex}`}>
															<img
																src={require(`../../images/${rows[0].platforms[pindex].tpName
																	?.toLocaleLowerCase()
																	.replaceAll(' ', '')}.png`)}
																alt={rows[0].platforms[pindex].tpName}
																width="24px"
																height="24px"
															/>
															<Typography {...themes.bodyMedium}>{rows[0].platforms[pindex].tpName}</Typography>
															<Divider id="bulk-edit-divider" />
														</div>
													)}
													{(!rows[0].platforms ||
														(rows[0].platforms && pindex >= rows[0].platforms.length && platformList)) && (
														<SingleSelectDropDown
															label="Select Platform"
															data={platformList}
															value={platformName[pindex]}
															width={476}
															hasError={platformNameError[pindex]}
															onSelectCallback={(e) => {
																setPlatformName((p) => {
																	p[pindex] = e;
																	return [...p];
																});
																setPlatformNameError(platformName.map((p) => p.length <= 0));
															}}
														/>
													)}
													<ToolTip
														tooltipText={
															isSameStatus || (rows[0].platforms && pindex >= rows[0].platforms.length)
																? ''
																: 'Sorry, bulk editing is not allowed since the selected suppliers have mixed scope statuses. Please select suppliers on the same platform with same scope status.'
														}
														tooltipColor={color.shades.red[10]}
														tooltipTextColor={color.shades.red[70]}
														children={
															<span>
																<SingleSelectDropDown
																	label="Scope Status"
																	data={supplierScopeStatuses}
																	value={platformStatus[pindex]}
																	width={230}
																	disabled={!isSameStatus && rows[0].platforms && rows[0].platforms.length > pindex}
																	hasError={platformStatusError[pindex]}
																	onSelectCallback={(e) => {
																		setPlatformStatus((p) => {
																			p[pindex] = e;
																			return [...p];
																		});
																		setPlatformStatusError(platformStatus.map((s) => s.length <= 0));
																		setPlatformDateError([]);
																	}}
																/>
															</span>
														}
													/>
													<ToolTip
														width={200}
														tooltipText={
															isSameDate || (rows[0].platforms && pindex >= rows[0].platforms.length)
																? ''
																: 'Sorry, bulk editing is not allowed since the selected suppliers have mixed scope start dates. Please select suppliers on the same platform with same scope start date.'
														}
														tooltipColor={color.shades.red[10]}
														tooltipTextColor={color.shades.red[70]}
														children={
															<span>
																<CustomDate
																	label="Scope Start Date"
																	value={platformDate[pindex]}
																	width={230}
																	disabled={
																		(!isSameDate && rows[0].platforms && rows[0].platforms.length > pindex) ||
																		platformStatus[pindex].toString() !== '1'
																	}
																	hasError={platformDateError[pindex]}
																	onChangeCallback={(e) => {
																		setPlatformDate((p) => {
																			p[pindex] = e;
																			return [...p];
																		});
																		setPlatformDateError([]);
																	}}
																/>
															</span>
														}
													/>

													<TextBox
														id={0}
														label="Leave comments here"
														value={platformComments[pindex]}
														width={476}
														isMultiline
														onChangeCallback={(e) =>
															setPlatformComments((p) => {
																p[pindex] = e;
																return [...p];
															})
														}
													/>
												</div>
											))}
										<CustomButton
											id="new-platform"
											text="New Platform"
											startIcon={<AddIcon sx={{ color: `${color.signal.info}` }} />}
											onClickCallback={addPlatform}
										/>
									</>
								</Grid>
							</>
						)}
					</Grid>
				)}
			</div>
			<div className={classes.footer}>
				<CustomButton id="discard" text="Discard" variant="contained" onClickCallback={onDiscardClick} />
				<CustomButton id="save" text="Save" variant="contained" onClickCallback={onSaveClick} />
			</div>
		</>
	);
};

const useStyles = makeStyles(() => ({
	supplierEdit: {
		width: '650px',
		height: 'calc(100% - 150px)',
		padding: '0px 24px',
		overflowY: 'scroll',
		'& .MuiInputLabel-formControl': {
			color: color.neutral[5],
		},
		'& .MuiInputBase-input': {
			color: color.text.dark,
		},
		'& #new-platform': {
			color: color.signal.info,
			border: 'none',
			padding: '0px',
		},
		'& .MuiDivider-root': {
			margin: '0px 10px',
		},
		'& #single-edit-divider': {
			width: '400px',
		},
		'& #bulk-edit-divider': {
			width: '360px',
		},
		'& .MuiSvgIcon-root': {
			cursor: 'pointer',
		},
	},
	divider: {
		color: color.neutral[8],
		display: 'flex',
		alignItems: 'center',
		padding: '0px 10px 10px',
	},
	platformContact: {
		display: 'flex',
		alignItems: 'center',
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		right: '10px',
		width: '100%',
		height: '70px',
		borderTop: `1px solid ${color.neutral[2]}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end',
		marginRight: '10px',
		'& #discard': {
			backgroundColor: color.primary[5],
			color: color.text.dark,
			width: '90px',
		},
		'& #save': {
			backgroundColor: color.primary[100],
			color: color.text.light,
			width: '90px',
		},
	},
}));
