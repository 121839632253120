import * as React from 'react';
import dayjs from 'dayjs';
import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import { makeStyles } from '@mui/styles';
import { colors } from '../../theme';
import { Table } from '../shared/table/table';
import { SupplierTableType } from '../../types/supplier.types';
import { ToolTip } from '../shared/tooltip/tooltip';

const color = colors();

type SupplierActionType = {
	onEditClickCallback: Function;
	onEmailClickHref: string;
};
const SupplierActionItems = ({ onEditClickCallback, onEmailClickHref }: SupplierActionType) => {
	const classes = useStyles();

	return (
		<div className={classes.iconButton} id="action-buttons">
			<ToolTip
				tooltipText="Edit"
				children={
					<IconButton id="edit_row" size="small" onClick={() => onEditClickCallback()}>
						<EditIcon fontSize="inherit" />
					</IconButton>
				}
			/>
			<ToolTip
				tooltipText="Send Email"
				children={
					<IconButton id="email_row" size="small" href={onEmailClickHref}>
						<EmailIcon fontSize="inherit" />
					</IconButton>
				}
			/>
		</div>
	);
};

export const SupplierTable = ({
	data,
	totalCount,
	page,
	onRowSelectedCallback,
	onCellClickCallback,
	onEditClickCallback,
	onEmailClickHref,
	onPageChange,
	rowsPerPage,
	onRowsPerPageChange,
}: SupplierTableType) => {
	const SupplierColumns: GridColDef[] = [
		{
			field: 'supplierName',
			headerName: 'Supplier Name',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 160,
			flex: 1,
		},
		{
			field: 'platform',
			headerName: 'Platform',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 150,
			flex: 1,
			renderCell: (params) => (
				<>
					{params.row?.platform && (
						<>
							<img
								src={require(`../../images/${params.row?.platform?.tpName?.toLocaleLowerCase().replaceAll(' ', '')}${
									params.row?.platform?.scopeStatusId === 1
										? '_reg'
										: params.row?.platform?.scopeStatusId === 4
										? '_inv'
										: ''
								}.png`)}
								alt={params.row?.platform?.tpName}
								width="24px"
								height="24px"
							/>
							{params.row?.platform?.scopeStatus}
						</>
					)}
				</>
			),
		},
		{
			field: 'scopeStartDate',
			headerName: 'Scope Start Date',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 130,
			flex: 1,
			valueGetter: (params) =>
				params.row?.platform?.scopeStartDate && dayjs(params.row?.platform?.scopeStartDate).format('DD MMM YYYY'),
		},
		{
			field: 'supplierId',
			headerName: 'SPUR ID',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 80,
			flex: 1,
		},
		{
			field: 'supplierCode',
			headerName: 'Supplier Code',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 80,
			flex: 1,
		},
		{
			field: 'supplierStatus',
			headerName: 'SPUR Status',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 80,
			flex: 1,
		},
		{
			field: 'supplierTypes',
			headerName: 'Supplier Type',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 135,
			flex: 1,
		},
		{
			field: 'countryName',
			headerName: 'Location',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 100,
			flex: 1,
		},
		{
			field: 'bouncedOrderCount',
			headerName: 'Bounced Orders Count',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 110,
			flex: 1,
		},
		{
			field: 'comments',
			headerName: 'Comments',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 110,
			flex: 1,
			valueGetter: (params) => params.row?.platform?.comments,
		},
		{
			field: 'action',
			headerName: '',
			hideSortIcons: true,
			sortable: false,
			disableColumnMenu: true,
			minWidth: 10,
			flex: 1,
			align: 'right',
			renderCell: () =>
				SupplierActionItems({
					onEditClickCallback: onEditClickCallback,
					onEmailClickHref: onEmailClickHref,
				}),
		},
	];

	return (
		<Table
			width="calc(100% - 100px)"
			height="calc(100% - 280px)"
			columns={SupplierColumns}
			data={data}
			totalCount={totalCount || data.length}
			page={page}
			pageSize={rowsPerPage}
			pageSizeOptions={[10, 25, 50, 100]}
			checkbox
			onRowSelectedCallback={onRowSelectedCallback}
			onCellClickCallback={onCellClickCallback}
			onPageChange={onPageChange}
			onRowsPerPageChange={onRowsPerPageChange}
		></Table>
	);
};

const useStyles = makeStyles(() => ({
	iconButton: {
		paddingRight: '5px',
		visibility: 'hidden',
		'& .MuiIconButton-root': {
			color: color.signal.info,
		},
	},
}));
