import React, { ReactElement, useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { Chip, Grid, IconButton, Radio, RadioGroup, Switch } from '@mui/material';
import { colors, theme } from '../../theme';
import { RoutineType, CreateRoutineType, ListType, PostResponse } from '../../types/routine.types';
import { Header } from '../shared/header/header';
import { TextBox } from '../shared/textbox/textbox';
import { SingleSelectDropDown } from '../shared/dropdown/singleSelect';
import { CustomButton } from '../shared/button/button';
import { CustomAlert } from '../shared/alert/alert';
import { CustomDialog } from '../shared/dialog/dialog';
import { useAsyncOperation } from '../../utils/use-async-operation';
import { saveVerificationRoutine } from '../../services/verification-routine-service';
import { ItemType } from '../../types/common.types';
import { Loader } from '../shared/loader/loader';

export const testid = 'create-routine';

const color = colors();
const themes = theme().typography;

export const CreateRoutinePanel = ({
	routineNames,
	certificateList,
	documentList,
	loggedInUser,
	onBackClick,
	onSaveClick,
}: CreateRoutineType): ReactElement => {
	const classes = useStyles();
	const TCDeadline: string = 'Two weeks after last TOD';
	const OMDDeadline: string = 'Before first TOD';

	const [routineName, setRoutineName] = useState<string>('');
	const [routineType, setRoutineType] = useState<number>(-1);
	const [hasCert, setHasCert] = useState<boolean>(false);
	const [filteredCertList, setFilteredCertList] = useState<ItemType[]>([...certificateList]);
	const [scopeCertificate, setScopeCertificate] = useState<ListType[]>([]);
	const [filteredDocList, setFilteredDocList] = useState<ItemType[]>([...documentList]);
	const [document, setDocument] = useState<ListType[]>([]);
	const [docName, setDocName] = useState<string>('');
	const [error, setError] = useState<string>('');
	const [openDialog, setOpenDialog] = useState<boolean>(false);

	const [saveRoutine, saveBusy, saveData, saveError] = useAsyncOperation<PostResponse>(saveVerificationRoutine);

	useEffect(() => {
		let listId: number[] = scopeCertificate.map((l) => l.keyId);
		setFilteredCertList(certificateList.filter((item) => !listId.includes(item.key)));
	}, [scopeCertificate]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let listId: number[] = document.map((l) => l.keyId);
		setFilteredDocList(documentList.filter((item) => !listId.includes(item.key)));
	}, [document]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (saveData) onSaveClick(document.filter((i) => i.keyId === -1).length > 0);
		if (saveError) setError('Error saving routine. Please try again.');
	}, [saveData, saveError]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleScopeCerts = (value: string) => {
		const selectedValue: ListType = {
			keyId: parseInt(value),
			name: certificateList.filter((s) => s.key === parseInt(value))[0]?.value?.toString() || '',
		};
		setScopeCertificate([...scopeCertificate, selectedValue]);
	};

	const handleDocument = (value: string) => {
		if (parseInt(value) === -1) setOpenDialog(true);
		else {
			const selectedValue: ListType = {
				keyId: parseInt(value),
				name: documentList.filter((s) => s.key === parseInt(value))[0]?.value?.toString() || '',
			};
			setDocument([...document, selectedValue]);
		}
	};

	const handleNewDocument = () => {
		setOpenDialog(false);
		if (documentList.filter((s) => s.value?.toString().toLowerCase() === docName.toLowerCase()).length > 0)
			setError(`Document - '${docName}' already exists in master list!`);
		else setDocument([...document, { keyId: -1, name: docName }]);
		setDocName('');
	};

	const handleSave = () => {
		let isValid: string = '';
		if (routineName.length === 0) isValid += '|Routine name is missing.';
		if (routineName.length > 0 && routineNames.includes(routineName.toLowerCase()))
			isValid += '|Routine with same name already exists!';
		if (routineType === -1) isValid += '|Routine type is missing.';
		if (hasCert && scopeCertificate.length === 0) isValid += '|At least one scope certificate is required.';
		if (hasCert && scopeCertificate.length > 3) isValid += '|A maximum of three scope certificaes are allowed.';
		if (document.length === 0) isValid += '|At least one document is required.';
		if (document.length > 4) isValid += '|A maximum of four documents are allowed.';
		if (isValid.length > 0) setError(isValid);
		else {
			setError('');
			let data: RoutineType = {
				id: null,
				routineName: routineName,
				routineGroup: routineName,
				scopeCertificates: scopeCertificate,
				documents: document,
				type: routineType === 0 ? 'TC' : 'OMD',
				deadline: routineType === 0 ? TCDeadline : OMDDeadline,
				updatedBy: loggedInUser,
				createdDate: null,
			};
			saveRoutine(data);
		}
	};

	const handleDiscard = () => {
		setRoutineName('');
		setRoutineType(-1);
		setHasCert(false);
		setScopeCertificate([]);
		setDocument([]);
		setError('');
	};

	return (
		<>
			<Loader key={`${testid}-loader`} open={saveBusy} message="Saving Routine..." />
			<div className={classes.flexDisplay}>
				<IconButton id="back-button" onClick={() => onBackClick(false)} data-testid={`${testid}-back`}>
					<ArrowBackIcon className={classes.backButton} />
				</IconButton>
				<Header title="Create a new routine" data-testid={`${testid}-header`} />
			</div>
			<div className={classes.content} data-testid={`${testid}-content`}>
				<Grid
					container
					rowSpacing={1}
					spacing={2}
					{...themes.bodyRegular}
					alignItems="center"
					justifyContent="center"
					height="auto"
					maxHeight="100%"
				>
					<Grid item xs={3} />
					<Grid item xs={9} className={classes.alert} key={`${testid}-alert`} data-testid={`${testid}-alert`}>
						<CustomAlert
							open={error.length > 0}
							message={error.split('|').map((e) => (
								<>
									{e.length > 0 && (
										<>
											{e}
											<br />
										</>
									)}
								</>
							))}
							severity="error"
							onCloseCallback={() => {
								setError('');
							}}
						/>
					</Grid>
					<Grid item xs={4} display="flex" justifyContent="end">
						Routine name<span style={{ color: color.signal.error }}>*</span>
					</Grid>
					<Grid item xs={8}>
						<TextBox label="" id={0} value={routineName} width={539} onChangeCallback={setRoutineName} />
					</Grid>
					<Grid item xs={4} display="flex" justifyContent="end">
						Routine type<span style={{ color: color.signal.error }}>*</span>
					</Grid>
					<Grid item xs={8}>
						<RadioGroup className={classes.radio}>
							<Grid container alignItems="center" className={routineType === 1 ? classes.radioDeselect : ''}>
								<Grid item xs="auto">
									<Radio
										id="routine-type-0"
										size="small"
										className={routineType === 1 ? classes.radioDeselect : ''}
										onChange={() => setRoutineType(0)}
										checked={routineType === 0}
										data-testid={`${testid}-radio-tc`}
									/>
								</Grid>
								<Grid item xs={1}>
									TC
								</Grid>
								<Grid item xs={10} fontStyle="italic">
									Deadline as two weeks after the last TOD
								</Grid>
							</Grid>
							<Grid container alignItems="center" className={routineType === 0 ? classes.radioDeselect : ''}>
								<Grid item xs="auto">
									<Radio
										id="routine-type-1"
										size="small"
										className={routineType === 0 ? classes.radioDeselect : ''}
										onChange={() => setRoutineType(1)}
										checked={routineType === 1}
										data-testid={`${testid}-radio-omd`}
									/>
								</Grid>
								<Grid item xs={1}>
									OMD
								</Grid>
								<Grid item xs={10} fontStyle="italic">
									Deadline as the day before the first TOD
								</Grid>
							</Grid>
						</RadioGroup>
					</Grid>
					<Grid item xs={4} display="flex" justifyContent="end">
						Has scope certificate
					</Grid>
					<Grid item xs={8} display="flex" alignItems="center">
						<Switch onClick={() => setHasCert(!hasCert)} className={classes.switch} data-testid={`${testid}-switch`} />
						<div>{hasCert ? 'Yes' : 'No'}</div>
					</Grid>
					{hasCert && (
						<>
							<Grid item xs={4} display="flex" justifyContent="end">
								Scope certificate<span style={{ color: color.signal.error }}>*</span>
							</Grid>
							<Grid item xs={8} data-testid={`${testid}-scope-cert`}>
								<SingleSelectDropDown
									label=""
									data={filteredCertList}
									value=""
									width={539}
									onSelectCallback={(e) => handleScopeCerts(e)}
								/>
							</Grid>
							{scopeCertificate.length > 0 &&
								scopeCertificate.map((cert, i) => (
									<>
										<Grid item xs={4} />
										<Grid item xs={8}>
											<Chip
												label={`${cert.keyId} ${cert.name}`}
												deleteIcon={<CloseIcon data-testid={`${testid}-sc-delete-${i}`} />}
												className={classes.chip}
												onDelete={() => setScopeCertificate(scopeCertificate.filter((_, idx) => idx !== i))}
												data-testid={`${testid}-sc-chip-${i}`}
											/>
										</Grid>
									</>
								))}
						</>
					)}
					<Grid item xs={4} display="flex" justifyContent="end">
						Document<span style={{ color: color.signal.error }}>*</span>
					</Grid>
					<Grid item xs={8} data-testid={`${testid}-doc`}>
						<SingleSelectDropDown
							label=""
							data={filteredDocList}
							value=""
							width={539}
							onSelectCallback={(e) => handleDocument(e)}
						/>
					</Grid>
					{document.length > 0 &&
						document.map((doc, i) => (
							<>
								<Grid item xs={4} />
								<Grid item xs={8}>
									<Chip
										label={doc.name}
										deleteIcon={<CloseIcon data-testid={`${testid}-doc-delete-${i}`} />}
										className={classes.chip}
										onDelete={() => setDocument(document.filter((_, idx) => idx !== i))}
										data-testid={`${testid}-doc-chip-${i}`}
									/>
								</Grid>
							</>
						))}
					<Grid className={classes.footer} data-testid={`${testid}-footer`}>
						<CustomButton id="discard" text="Discard" variant="contained" onClickCallback={handleDiscard} />
						<CustomButton id="save" text="Save" variant="contained" onClickCallback={handleSave} />
					</Grid>
				</Grid>
			</div>
			<CustomDialog
				key={`${testid}-add-doc`}
				open={openDialog}
				title="Add a document"
				yesButtonText="Add"
				noButtonText="Cancel"
				onYesClickCallback={handleNewDocument}
				onNoClickCallback={() => setOpenDialog(false)}
				onCloseCallback={() => setOpenDialog(false)}
				component={
					<>
						<div className={classes.label}>Document name</div>
						<TextBox id={`${testid}-doc-name`} label="" onChangeCallback={setDocName} width={348} />
					</>
				}
			/>
		</>
	);
};

const useStyles = makeStyles(() => ({
	flexDisplay: {
		display: 'flex',
		alignItems: 'center',
	},
	backButton: {
		width: '40px !important',
		height: '40px !important',
		color: color.shades.black,
	},
	content: {
		height: '90%',
		overflowY: 'scroll',
		display: 'flex',
		alignItems: 'center',
	},
	alert: {
		'& .MuiAlert-standard': {
			width: 700,
			border: `1px solid ${color.shades.red[70]}`,
			color: color.shades.red[10],
		},
		'& .MuiAlert-icon': {
			alignItems: 'center',
			color: `${color.shades.red[70]} !important`,
		},
		'& .MuiAlert-message': {
			color: color.shades.red[70],
		},
		'& .MuiAlert-action': {
			alignItems: 'center',
			color: color.shades.red[70],
		},
	},
	radio: {
		'& .MuiRadio-root': {
			color: color.shades.black,
		},
		'& .MuiRadio-root.Mui-checked': {
			color: color.shades.black,
		},
	},
	radioDeselect: {
		color: `${color.neutral[6]} !important`,
	},
	switch: {
		padding: '8px !important',
		'& .MuiSwitch-track': {
			borderRadius: '16px',
		},
		'& .Mui-checked+.MuiSwitch-track': {
			backgroundColor: `${color.signal.info} !important`,
			opacity: '1 !important',
		},
		'& .MuiSwitch-thumb': {
			color: 'white',
		},
	},
	chip: {
		width: 539,
		borderRadius: '0px !important',
		margin: '0px 8px !important',
		justifyContent: 'space-between !important',
		backgroundColor: `${color.shades.blue[10]} !important`,
		'& svg': {
			color: `${color.primary[90]} !important`,
		},
	},
	footer: {
		display: 'flex',
		width: '50%',
		justifyContent: 'end',
		borderTop: `1px solid ${color.neutral[2]}`,
		marginTop: '24px',
		padding: '24px',
		'& #discard': {
			backgroundColor: color.primary[5],
			color: color.text.dark,
			width: '90px',
		},
		'& #save': {
			backgroundColor: color.primary[100],
			color: color.text.light,
			width: '90px',
		},
	},
	label: {
		...themes.metaText,
		padding: '0px 8px',
		marginBottom: '-5px',
	},
}));
