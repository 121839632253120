import { StatusType } from 'src/types/routine.types';
import dayjs from 'dayjs';

export const SkipValues = (shell: boolean, lining: boolean, padding: boolean, hasPct: boolean): string => {
	let skips: string[] = [];

	shell && skips.push('Shell');
	lining && skips.push('Lining');
	padding && skips.push('Padding');

	return skips.length > 0 ? (skips.length === 3 ? 'All positions' : skips.join(', ')) : hasPct ? 'All positions' : '';
};

export const GetStatus = (activeDate: string | null, endDate: string | null): StatusType => {
	let matStatus = {} as StatusType;
	let currentDate = new Date();
	let sDate = activeDate === null ? null : new Date(activeDate);
	let eDate = endDate === null ? null : new Date(endDate);
	const aDayInMs = 24 * 60 * 60 * 1000;

	let startDateDiff: number =
		activeDate && sDate ? Math.round((sDate.getTime() - currentDate.getTime()) / aDayInMs) + 1 : 0;
	let endDateDiff: number = endDate && eDate ? Math.round((eDate.getTime() - currentDate.getTime()) / aDayInMs) + 1 : 0;

	if (!activeDate && !endDate) {
		matStatus.status = 'Active';
		matStatus.statusName = 'Active';
		matStatus.backgroundColor = '#EDF7EE';
		matStatus.textColor = '#3D8C40';
	} else if (activeDate && !endDate && startDateDiff <= 0) {
		matStatus.status = 'Active';
		matStatus.statusName = 'Active';
		matStatus.statusDuration = `Since ${dayjs(activeDate).format('DD MMM, YYYY')}`;
		matStatus.backgroundColor = '#EDF7EE';
		matStatus.textColor = '#3D8C40';
	} else if (activeDate && startDateDiff > 0) {
		matStatus.status = `Active in ${startDateDiff} ${startDateDiff === 1 ? 'day' : 'days'}`;
		matStatus.statusName = 'Active soon';
		matStatus.statusDuration = `Starts from ${dayjs(activeDate).format('DD MMM, YYYY')}`;
		matStatus.backgroundColor = '#FFF4E6';
		matStatus.textColor = '#B06200';
	} else if (endDate && endDateDiff > 0 && activeDate) {
		matStatus.status = `Active (ends in ${endDateDiff} ${endDateDiff === 1 ? 'day' : 'days'})`;
		matStatus.statusName = 'Ends soon';
		matStatus.statusDuration = `Active from ${dayjs(activeDate).format('DD MMM, YYYY')} to ${dayjs(endDate).format(
			'DD MMM, YYYY'
		)}`;
		matStatus.backgroundColor = '#EDF7EE';
		matStatus.textColor = '#3D8C40';
	} else if (endDate && endDateDiff <= 0 && activeDate) {
		matStatus.status = 'Deactivated';
		matStatus.statusName = 'Inactive';
		matStatus.statusDuration = `Active from ${dayjs(activeDate).format('DD MMM, YYYY')} to ${dayjs(endDate).format(
			'DD MMM, YYYY'
		)}`;
		matStatus.backgroundColor = '#E0E0E0';
		matStatus.textColor = '#616161';
	} else if (endDate && endDateDiff > 0 && !activeDate) {
		matStatus.status = `Active (ends in ${endDateDiff} ${endDateDiff === 1 ? 'day' : 'days'})`;
		matStatus.statusName = 'Ends soon';
		matStatus.statusDuration = `Active till ${dayjs(endDate).format('DD MMM, YYYY')}`;
		matStatus.backgroundColor = '#EDF7EE';
		matStatus.textColor = '#3D8C40';
	} else if (endDate && endDateDiff <= 0 && !activeDate) {
		matStatus.status = 'Deactivated';
		matStatus.statusName = 'Inactive';
		matStatus.statusDuration = `Active till ${dayjs(endDate).format('DD MMM, YYYY')}`;
		matStatus.backgroundColor = '#E0E0E0';
		matStatus.textColor = '#616161';
	}

	return matStatus;
};
