import { ItemType } from '../types/common.types';

export const SupplierTabs: ItemType[] = [
	{ key: 0, value: 'All' },
	{ key: 1, value: 'Textile Genesis' },
	{ key: 2, value: 'Retraced' },
];

export const SortOptions: ItemType[] = [
	{ key: 0, value: 'Bounced orders count' },
	{ key: 1, value: 'Supplier name (A-Z)' },
	{ key: 2, value: 'Supplier name (Z-A)' },
	{ key: 3, value: 'Scope start date' },
	{ key: 4, value: 'SPUR ID' },
];
