import React, { ReactElement, useState } from 'react';
import { Menu, MenuItem, ProSidebar, SidebarFooter } from 'react-pro-sidebar';
import { Avatar, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import FactoryIcon from '@mui/icons-material/Factory';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../theme';
import { msalInstance } from '../../../config/msalConfig';

const color = colors();
export const SideNavMenu = (): ReactElement => {
	const classes = useStyles();
	const themes = theme().typography;
	const [isCollapsed, setIsCollapsed] = useState(true);
	const [selected, setSelected] = useState('/supplier');
	const loggedInUser: string = msalInstance.getActiveAccount()?.name?.split('(')[0] || '';

	const stringAvatar = (name: string) => {
		return {
			children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
		};
	};

	const Item = ({ title, to, icon, selected, setSelected, className }: any) => (
		<MenuItem
			active={selected === to}
			onClick={() => setSelected(to)}
			icon={icon}
			className={`${classes.menuItem} ${className || ''}`}
		>
			<Typography {...themes.h5}>{title}</Typography>
			<Link to={to} />
		</MenuItem>
	);

	return (
		<Box className={classes.sideNavMenu}>
			<ProSidebar collapsed={isCollapsed}>
				<Menu>
					<MenuItem onClick={() => setIsCollapsed(!isCollapsed)} icon={<MenuOutlinedIcon />}>
						{!isCollapsed && (
							<Box display="flex">
								<Typography variant="h5" className={classes.menuTitle}>
									TraceTracker
								</Typography>
							</Box>
						)}
					</MenuItem>

					<Item title="Supplier" to="/supplier" icon={<FactoryIcon />} selected={selected} setSelected={setSelected} />
					<Item
						title="Routines"
						to="/routine"
						icon={<WorkspacePremiumIcon />}
						selected={selected}
						setSelected={setSelected}
					/>
					<Item
						title="Material"
						to="/material"
						icon={<CheckroomIcon />}
						selected={selected}
						setSelected={setSelected}
					/>
					<Item
						title="Go to PowerBI"
						to="/powerbi"
						icon={<img alt="powerbi" src={require('../../../images/power-bi.png')} className={classes.pngIcon} />}
						selected={selected}
						setSelected={setSelected}
						className={classes.powerbi}
					/>
				</Menu>
				<SidebarFooter>
					<Menu>
						<Item
							title={loggedInUser}
							to="/user"
							icon={<Avatar {...stringAvatar(loggedInUser)} className={classes.footerItem} />}
							selected={selected}
							setSelected={setSelected}
						/>
					</Menu>
				</SidebarFooter>
			</ProSidebar>
		</Box>
	);
};

const useStyles = makeStyles({
	sideNavMenu: {
		backgroundColor: color.shades.white,
		color: color.shades.white,
		'& .pro-sidebar': {
			width: '224px',
			minWidth: '224px',
		},
		'& .pro-sidebar .pro-menu': {
			padding: '0px',
		},
		'& .pro-sidebar.collapsed': {
			width: '48px',
			minWidth: '48px',
		},
		'& .pro-sidebar-layout': {
			overflow: 'hidden !important',
		},
		'& .pro-menu-item.active': {
			color: `${color.signal.info} !important`,
			borderBottom: `1px solid ${color.neutral[9]}`,
			backgroundColor: color.neutral[9],
			borderLeft: `2px solid ${color.signal.info}`,
		},
		'& .pro-inner-item': {
			padding: '8px 16px !important',
			gap: '10px',
		},
		'& .pro-inner-item:hover': {
			color: `${color.signal.info} !important`,
		},
		'& .pro-sidebar.collapsed .pro-inner-item': {
			padding: '8px 5px !important',
		},
		'& .pro-sidebar-footer': {
			height: '48px',
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0,
		},
	},
	menuTitle: {
		fontWeight: '600 !important',
		fontSize: '20px !important',
		lineHeight: '32px !important',
		color: color.shades.white,
	},
	menuItem: {
		color: color.shades.white,
	},
	footerItem: {
		backgroundColor: `${color.shades.white} !important`,
		color: `${color.primary[100]} !important`,
		fontSize: '12px !important',
		width: '24px !important',
		height: '24px !important',
	},
	pngIcon: {
		width: '24px',
		height: '24px',
		filter: 'invert(1)',
	},
	powerbi: {
		position: 'absolute !important' as any,
		bottom: '50px',
		width: '100%',
	},
});
