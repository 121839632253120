import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { MultiSelectType } from '../../../types/common.types';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../theme';
import { Checkbox, ListItemText, Select, SelectChangeEvent } from '@mui/material';

export const testid = 'multi-select';

const color = colors();
const themes = theme().typography;
export const MultiSelectDropDown = ({
	label,
	width = 150,
	data,
	value,
	showValue,
	onSelectCallback,
}: MultiSelectType) => {
	const classes = useStyles();
	const [selValue, setSelValue] = useState<string[]>(value);

	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: 48 * 4.5 + 8,
				width: 250,
			},
		},
	};

	useEffect(() => {
		setSelValue(value);
	}, [value]);

	const handleChange = (event: SelectChangeEvent<typeof selValue>) => {
		const {
			target: { value },
		} = event;
		let data = typeof value === 'string' ? value.split(',') : value;
		setSelValue(data);
		onSelectCallback && onSelectCallback(data);
	};

	return (
		<FormControl sx={{ m: 1, width: { width } }} size="small" className={classes.dropdown} data-testid={testid}>
			<InputLabel id={`multi-input-${label}`} data-testid={`${testid}-label`}>
				{label}
			</InputLabel>
			<Select
				data-testid={`${testid}-dropdown`}
				labelId={`multi-${label}`}
				id={`multi-${label}`}
				multiple
				value={selValue}
				onChange={handleChange}
				label={label}
				MenuProps={MenuProps}
				renderValue={(selected) =>
					showValue ? selected.join(', ') : selected.length > 1 ? `${label} (${selected.length})` : selected[0]
				}
				sx={{
					'&:hover': {
						'&& fieldset': {
							border: `1px solid ${color.shades.blue[50]}`,
						},
					},
				}}
			>
				{data?.map((item, i) => (
					<MenuItem value={item} key={i} data-testid={`${testid}-item-${i}`}>
						<Checkbox
							checked={selValue.indexOf(item) > -1}
							style={{
								color: color.primary[90],
							}}
						/>
						<ListItemText primary={item} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

const useStyles = makeStyles(() => ({
	dropdown: {
		'& .MuiInputLabel-formControl': {
			...themes.bodyRegular,
			color: color.neutral[5],
		},
		'& .Mui-focused.MuiInputLabel-formControl': {
			color: color.signal.info,
		},
		'& .MuiInputBase-input': {
			...themes.bodyRegular,
			color: color.neutral[8],
		},
		'& .MuiSelect-icon': {
			color: color.neutral[5],
		},
		'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: `2px solid ${color.signal.info} !important`,
		},
		'& .MuiSelect-select': {
			display: 'flex',
		},
	},
}));
