import * as React from 'react';
import TextField from '@mui/material/TextField';
import { TextBoxType } from '../../../types/common.types';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../theme';

export const testid = 'search';

const color = colors();
const themes = theme().typography;
export const TextBox = ({
	id,
	label,
	labelText,
	value,
	isMultiline,
	variant,
	width = 120,
	disabled,
	onChangeCallback,
	placeHolder,
}: TextBoxType) => {
	const classes = useStyles();

	return (
		<div>
			<div className={classes.label}>{labelText}</div>
			<TextField
				id={id.toString()}
				data-testid={testid}
				label={label}
				variant={variant || 'outlined'}
				size="small"
				placeholder={placeHolder}
				sx={{
					m: 1,
					width: { width },
					'&:hover': {
						'&& fieldset': {
							border: disabled ? `1px solid ${color.neutral[5]}` : `1px solid ${color.shades.blue[50]}`,
						},
					},
				}}
				value={value}
				className={classes.textbox}
				multiline={isMultiline}
				rows={isMultiline ? 4 : 1}
				disabled={disabled}
				onChange={(e) => onChangeCallback && onChangeCallback(e.target.value)}
			/>
		</div>
	);
};

const useStyles = makeStyles(() => ({
	textbox: {
		'& .MuiInputLabel-formControl': {
			...themes.bodyRegular,
			color: color.neutral[5],
		},
		'& .Mui-focused.MuiInputLabel-formControl': {
			color: color.signal.info,
		},
		'& .MuiInputBase-input': {
			...themes.bodyRegular,
			color: color.neutral[8],
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: color.neutral[5],
		},
		'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: `2px solid ${color.signal.info} !important`,
		},
	},
	label: {
		...themes.metaText,
		padding: '0px 8px',
		marginBottom: '-5px',
	},
}));
