import React, { useEffect, useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import dayjs from 'dayjs';
import { colors, theme } from '../../theme';
import mockPlatformList from '../../services/mock/platformList.json';
import { SingleSelectDropDown } from '../../components/shared/dropdown/singleSelect';
import { CustomDate } from '../../components/shared/date/date';
import { TextBox } from '../../components/shared/textbox/textbox';
import { CustomButton } from '../../components/shared/button/button';
import { HistoryTimeline } from '../../components/shared/timeline/timeline';
import { ItemType } from '../../types/common.types';
import { MaterialEditType, MaterialUpsertType, PositionType, ProductionGroupType } from '../../types/material.types';
import { CustomTab } from '../../components/shared/tab/tab';
import { GanttChart } from '../../components/shared/gantt/gantt-chart';
import { Task } from 'gantt-task-react';
import { Positions, ProductionGroups } from '../../config/materialTabConfig';
import { CustomDialog } from '../../components/shared/dialog/dialog';
import { msalInstance } from '../../config/msalConfig';
import { ToolTip } from '../../components/shared/tooltip/tooltip';

export const testid = 'material-platform-edit';

const color = colors();
const themes = theme().typography;

export const MaterialPlatformEdit = ({ data, isSave }: MaterialEditType) => {
	const classes = useStyles();
	const today = new Date();
	const tabs: ItemType[] = [
		{
			key: 0,
			value: 'Tracking Info',
		},
		{
			key: 1,
			value: 'History',
		},
	];

	const [activeTab, setActiveTab] = useState<number>(0);
	const [platformList, setPlatformList] = useState<ItemType[]>([]);
	const [platformName, setPlatformName] = useState<string>(data.platform?.tpid.toString() || '');
	const [hasComment, setHasComment] = useState<boolean>(false);
	const [comment, setComment] = useState<string>(data.platform?.comments?.toString() || '');
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [isPosition, setIsPosition] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [filteredPosList, setFilteredPosList] = useState<ItemType[]>([...Positions]);
	const [filteredPgList, setFilteredPgList] = useState<ItemType[]>([...ProductionGroups]);
	const [value, setValue] = useState<string>('-1');
	const [startDate, setStartDate] = useState<string>('');
	const [endDate, setEndDate] = useState<string>('');
	const [positionTask, setPositionTask] = useState<Task[]>([]);
	const [productionGroupTask, setProductionGroupTask] = useState<Task[]>([]);

	useEffect(() => {
		const pfList: ItemType[] = [];
		mockPlatformList.map((p) =>
			pfList.push({
				key: p.key,
				value: (
					<>
						<img
							src={require(`../../images/${p.value.toLocaleLowerCase().replaceAll(' ', '')}.png`)}
							alt={p.value}
							width="24px"
							height="24px"
						/>
						{p.value}
					</>
				),
			})
		);
		setPlatformList([...pfList]);
		initializeTask();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let listId: number[] = positionTask.map((l) => parseInt(l.id));
		setFilteredPosList(Positions.filter((item) => !listId.includes(item.key)));
	}, [positionTask]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let listId: number[] = productionGroupTask.map((l) => parseInt(l.id));
		setFilteredPgList(ProductionGroups.filter((item) => !listId.includes(item.key)));
	}, [productionGroupTask]); // eslint-disable-line react-hooks/exhaustive-deps

	const initializeTask = () => {
		let posTask =
			data.platform?.position.map((p): Task => {
				return {
					id: p.positionId.toString(),
					name: `${p.scopeStartDate} - ${p.scopeEndDate || 'Present'}`,
					project: p.positionName,
					type: 'task',
					start: new Date(p.scopeStartDate),
					end:
						p.scopeEndDate && p.scopeEndDate.length > 0
							? new Date(p.scopeEndDate)
							: new Date(`31 Dec ${today.getFullYear() + 1}`),
					progress: 100,
					styles: { progressColor: color.signal.info30, progressSelectedColor: color.signal.info30 },
				};
			}) || [];
		setPositionTask(posTask);

		let pgTask =
			data.platform?.productionGroup.map((p): Task => {
				return {
					id: p.productionGroupId.toString(),
					name: `${p.scopeStartDate} - ${p.scopeEndDate || 'Present'}`,
					project: p.productionGroupName,
					type: 'task',
					start: new Date(p.scopeStartDate),
					end:
						p.scopeEndDate && p.scopeEndDate.length > 0
							? new Date(p.scopeEndDate)
							: new Date(`31 Dec ${today.getFullYear() + 1}`),
					progress: 100,
					styles: { progressColor: color.signal.warning30, progressSelectedColor: color.signal.warning30 },
				};
			}) || [];
		setProductionGroupTask(pgTask);

		setHasComment(comment.length > 0);
	};

	const handleAdd = (isPosition: boolean) => {
		setIsPosition(isPosition);
		setOpenDialog(true);
	};

	const handleEdit = (id: string, startDate: string, endDate: string, isPosition: boolean) => {
		setIsEdit(true);
		setIsPosition(isPosition);
		setOpenDialog(true);

		setValue(id);
		let inactive = today > new Date(startDate) && today > new Date(endDate);
		setStartDate(inactive ? '' : startDate);
		setEndDate(inactive ? '' : endDate);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setIsEdit(false);
		setValue('-1');
		setStartDate('');
		setEndDate('');
	};

	const handleSaveTask = () => {
		let task: Task = {
			id: value,
			name: `${dayjs(startDate).format('DD MMM YYYY')} - ${
				endDate.length === 0 ? 'Present' : dayjs(endDate).format('DD MMM YYYY')
			}`,
			project: isPosition
				? Positions.filter((p) => p.key === parseInt(value))[0].value?.toString() || ''
				: ProductionGroups.filter((p) => p.key === parseInt(value))[0].value?.toString() || '',
			type: 'task',
			start: new Date(startDate),
			end: endDate.length > 0 ? new Date(endDate) : new Date(`31 Dec ${today.getFullYear() + 1}`),
			progress: 100,
			styles: {
				progressColor: isPosition ? color.signal.info30 : color.signal.warning30,
				progressSelectedColor: isPosition ? color.signal.info30 : color.signal.warning30,
			},
		};

		if (isEdit) {
			isPosition
				? setPositionTask((prev) => {
						let index = prev.findIndex((p) => p.id === task.id);
						prev[index] = task;
						return [...prev];
				  })
				: setProductionGroupTask((prev) => {
						let index = prev.findIndex((p) => p.id === task.id);
						prev[index] = task;
						return [...prev];
				  });
		} else {
			isPosition ? setPositionTask((prev) => [...prev, task]) : setProductionGroupTask((prev) => [...prev, task]);
		}
		handleCloseDialog();
	};

	const onDiscardClick = () => {
		setPlatformName(data.platform?.tpid.toString() || '');
		setComment(data.platform?.comments?.toString() || '');
		initializeTask();
	};

	const onSaveClick = () => {
		const loggedInUser = msalInstance.getActiveAccount();
		let positions: PositionType[] = positionTask.map((p) => {
			return {
				positionId: parseInt(p.id),
				positionName: p.project || '',
				scopeStartDate: dayjs(p.start).format('DD MMM YYYY'),
				scopeEndDate: p.name.toLowerCase().includes('present') ? '' : dayjs(p.end).format('DD MMM YYYY'),
			};
		});
		let productionGroups: ProductionGroupType[] = productionGroupTask.map((p) => {
			return {
				productionGroupId: parseInt(p.id),
				productionGroupName: p.project || '',
				scopeStartDate: dayjs(p.start).format('DD MMM YYYY'),
				scopeEndDate: p.name.toLowerCase().includes('present') ? '' : dayjs(p.end).format('DD MMM YYYY'),
			};
		});

		let saveData: MaterialUpsertType = {
			id: data.id,
			materialId: data.materialId,
			platform: {
				tpid: parseInt(platformName),
				tpName: mockPlatformList.filter((p) => p.key === parseInt(platformName))[0].value,
				position: positions,
				productionGroup: productionGroups,
				comments: comment,
			},
			user: {
				loggedInUserName: (loggedInUser && loggedInUser.name?.split('(')[0])?.trim() || '',
				loggedInUserEmail: (loggedInUser && loggedInUser.username) || '',
			},
		};

		alert(JSON.stringify(saveData));
		isSave(true);
	};

	return (
		<>
			<div className={classes.materialEdit}>
				<CustomTab data={tabs} value={activeTab} onChangeCallback={setActiveTab} />
				{activeTab === 0 ? (
					<>
						<Grid container rowSpacing={1} spacing={2} {...themes.bodyRegular} paddingTop={'15px'} alignItems="center">
							<Grid item xs={2.5} color={color.neutral[8]}>
								Platform
							</Grid>
							<Grid item xs={9.5}>
								<div className={classes.divComp}>
									<SingleSelectDropDown
										label=""
										data={platformList}
										value={platformName}
										width={476}
										onSelectCallback={setPlatformName}
									/>
									{!hasComment && (
										<div id="add-comment" className={classes.textButton} onClick={() => setHasComment(true)}>
											Add comments
										</div>
									)}
								</div>
							</Grid>
							{hasComment && (
								<>
									<Grid item xs={2.5} color={color.neutral[8]} alignItems={'center'}>
										Comment
									</Grid>
									<Grid item xs={9.5}>
										<div className={classes.divComp}>
											<TextBox
												id={`${testid}-comment`}
												label=""
												labelText=""
												width={476}
												value={comment || ''}
												onChangeCallback={setComment}
											/>
											<ToolTip
												tooltipText="Delete comment"
												children={
													<IconButton
														id="delete-comment"
														className={classes.iconButton}
														onClick={() => {
															setHasComment(false);
															setComment('');
														}}
													>
														<DeleteOutlineIcon />
													</IconButton>
												}
											/>
										</div>
									</Grid>
								</>
							)}
						</Grid>
						<GanttChart
							title="Position"
							maxCount={Positions.length}
							chartData={positionTask}
							handleAddTask={() => handleAdd(true)}
							handleEditTask={handleEdit}
						/>
						<GanttChart
							title="Production Group"
							maxCount={ProductionGroups.length}
							chartData={productionGroupTask}
							handleAddTask={() => handleAdd(false)}
							handleEditTask={handleEdit}
						/>
					</>
				) : (
					<>{data.history && <HistoryTimeline logData={data.history} />}</>
				)}
			</div>
			<div className={classes.footer}>
				<CustomButton id="discard" text="Discard" variant="contained" onClickCallback={onDiscardClick} />
				{platformName.length === 0 || positionTask.length === 0 || productionGroupTask.length === 0 ? (
					<CustomButton id="save-disabled" text="Save" variant="contained" disabled={true} />
				) : (
					<CustomButton id="save" text="Save" variant="contained" onClickCallback={onSaveClick} />
				)}
			</div>
			<CustomDialog
				open={openDialog}
				title={`${isEdit ? 'Edit' : 'Add'} ${isPosition ? 'Position' : 'Production Group'}`}
				yesButtonText="Apply"
				noButtonText="Cancel"
				yesButtonDisable={value.length === 0 || value === '-1' || startDate.length === 0}
				onYesClickCallback={() => {
					handleSaveTask();
				}}
				onNoClickCallback={handleCloseDialog}
				onCloseCallback={handleCloseDialog}
				component={
					<Grid container rowSpacing={2} spacing={2} {...themes.bodyRegular} alignItems="center">
						<Grid item xs={4} color={color.neutral[8]}>
							Position{!isEdit && <span style={{ color: color.signal.error }}>*</span>}
						</Grid>
						{isEdit ? (
							<Grid item xs={8} paddingLeft={'24px !important'} color={color.text.dark}>
								{isPosition
									? Positions.filter((p) => p.key === parseInt(value))[0].value
									: ProductionGroups.filter((p) => p.key === parseInt(value))[0].value}
							</Grid>
						) : (
							<Grid item xs={8} color={color.text.dark}>
								<SingleSelectDropDown
									label=""
									data={isPosition ? filteredPosList : filteredPgList}
									value={value}
									width={230}
									onSelectCallback={setValue}
								/>
							</Grid>
						)}
						{isEdit && startDate.length > 0 && today > new Date(startDate) ? (
							<>
								<Grid item xs={4} color={color.neutral[8]}>
									Scope Start Date
								</Grid>
								<Grid item xs={8} paddingLeft={'24px !important'} color={color.text.dark}>
									{dayjs(startDate).format('DD MMM, YYYY')}
								</Grid>
							</>
						) : (
							<>
								<Grid item xs={4} color={color.neutral[8]}>
									Scope Start Date<span style={{ color: color.signal.error }}>*</span>
								</Grid>
								<Grid item xs={8} color={color.text.dark}>
									<CustomDate
										label=""
										value={startDate}
										width={230}
										onChangeCallback={setStartDate}
										disablePast={true}
										disableToday={true}
									/>
								</Grid>
							</>
						)}
						<Grid item xs={4} color={color.neutral[8]}>
							Scope End Date
						</Grid>
						<Grid item xs={8} color={color.text.dark}>
							<CustomDate label="" value={endDate} width={230} onChangeCallback={setEndDate} disablePast={true} />
						</Grid>
					</Grid>
				}
			/>
		</>
	);
};

const useStyles = makeStyles(() => ({
	materialEdit: {
		width: '898px',
		height: 'calc(100% - 150px)',
		padding: '0px 24px',
		overflowY: 'scroll',
		'& .MuiInputLabel-formControl': {
			color: color.neutral[5],
		},
		'& .MuiInputBase-input': {
			color: color.text.dark,
		},
	},
	divComp: {
		display: 'flex',
		alignItems: 'center',
		'& #delete-comment': {
			marginTop: '-10px',
		},
	},
	textButton: {
		cursor: 'pointer',
		color: color.signal.info,
		margin: '-8px 0px 0px 15px',
	},
	iconButton: {
		padding: '5px !important',
		'& svg': {
			width: '16px',
			height: '16px',
			color: color.primary[90],
		},
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		right: '10px',
		width: '100%',
		height: '70px',
		borderTop: `1px solid ${color.neutral[2]}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end',
		marginRight: '10px',
		'& #discard': {
			backgroundColor: color.primary[5],
			color: color.text.dark,
			width: '90px',
		},
		'& #save': {
			backgroundColor: color.primary[100],
			color: color.text.light,
			width: '90px',
		},
		'& #save-disabled': {
			backgroundColor: color.neutral[4],
			color: color.text.disabled,
			width: '90px',
		},
	},
}));
