import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import AppSettings from '../appSettings.json';

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.

export const graphScopes = {
	scopes: ['https://graph.microsoft.com/.default'],
};

export const graphConfig = {
	graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const msalConfig: Configuration = {
	auth: {
		clientId: AppSettings.clientId,
		authority: `https://login.microsoftonline.com/${AppSettings.tenantId}`,
		redirectUri: AppSettings.redirectUri,
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
	},
};

export const loginRequest = {
	scopes: [AppSettings.loginScope],
};

export const msalInstance = new PublicClientApplication(msalConfig);
