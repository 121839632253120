export const colors = () => ({
	neutral: {
		1: '#FAFAFA',
		2: '#F5F5F5',
		3: '#EEEEEE',
		4: '#E0E0E0',
		5: '#BDBDBD',
		6: '#9E9E9E',
		7: '#757575',
		8: '#616161',
		9: '#424242',
		10: '#212121',
	},
	primary: {
		100: '#222222',
		90: '#383838',
		80: '#333333',
		10: '#E9E9E9',
		5: '#F4F4F4',
		110: '#1F1F1F',
	},
	signal: {
		infoDark: '#145A92',
		info: '#2196F3',
		infoLight: '#E9F5FE',
		info30: '#BCE0FB',
		progress: '#FCA333',
		success: '#5EB762',
		error: '#FF5252',
		warning30: '#FEDDB3',
	},
	shades: {
		black: '#000000',
		white: '#FFFFFF',
		blue: {
			10: '#E9F5FE',
			50: '#7AC0F8',
		},
		red: {
			10: '#FFEEEE',
			30: '#F5B7B1',
			60: '#D32F2F',
			70: '#CC4242',
		},
	},
	text: {
		dark: '#000000',
		light: '#FFFFFF',
		disabled: '#BDBDBD',
	},
});

export const theme = () => {
	return {
		typography: {
			h1: {
				fontSize: '38px',
				lineHeight: '46px',
				fontWeight: 600,
			},
			h2: {
				fontSize: '30px',
				lineHeight: '40px',
				fontWeight: 600,
			},
			h3: {
				fontSize: '24px',
				lineHeight: '32px',
				fontWeight: 600,
			},
			h4: {
				fontSize: '20px',
				lineHeight: '28px',
				fontWeight: 600,
			},
			h5: {
				fontSize: '16px',
				lineHeight: '24px',
				fontWeight: 600,
			},
			bodyRegular: {
				fontSize: '14px',
				lineHeight: '22px',
				fontWeight: 400,
			},
			bodyMedium: {
				fontSize: '14px',
				lineHeight: '22px',
				fontWeight: 600,
			},
			bodyBold: {
				fontSize: '14px',
				lineHeight: '22px',
				fontWeight: 700,
			},
			metaText: {
				fontSize: '12px',
				lineHeight: '20px',
				fontWeight: 400,
			},
			overline: {
				fontSize: '12px',
				lineHeight: '32px',
				fontWeight: 400,
				letterSpacing: 2,
			},
		},
	};
};
