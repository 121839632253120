import { ItemType } from '../types/common.types';

export const SortOptions: ItemType[] = [
	{ key: 0, value: 'Material ID' },
	{ key: 1, value: 'Material name (A-Z)' },
	{ key: 2, value: 'Material name (Z-A)' },
	{ key: 3, value: 'Scope start date' },
];

export const Positions: ItemType[] = [
	{ key: 1, value: 'Shell' },
	{ key: 2, value: 'Lining' },
	{ key: 3, value: 'Trim' },
	{ key: 4, value: 'Padding' },
];

export const ProductionGroups: ItemType[] = [
	{ key: 1, value: 'Accessories' },
	{ key: 2, value: 'Beauty' },
	{ key: 3, value: 'Cosmetic' },
	{ key: 4, value: 'Denim' },
	{ key: 5, value: 'Footwear, Bag&Belt' },
	{ key: 6, value: 'Home (hardgoods)' },
	{ key: 7, value: 'Home (textile)' },
	{ key: 8, value: 'Jersey' },
	{ key: 9, value: 'Knitted' },
	{ key: 10, value: 'NCG' },
	{ key: 11, value: 'Shoes' },
	{ key: 12, value: 'Socks/Tights' },
	{ key: 13, value: 'UW/SW' },
	{ key: 14, value: 'Woven' },
];
