import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthWrapper from './components/authentication/authWrapper';
import { Layout } from './components/layout/layout';
import { routes } from './config/routeConfig';

const queryClient = new QueryClient();

const App = (): React.ReactElement => {
	return (
		<div data-testid="app">
			<AuthWrapper>
				<QueryClientProvider client={queryClient}>
					<Layout route={routes} />
				</QueryClientProvider>
			</AuthWrapper>
		</div>
	);
};

export default App;
