import { makeStyles } from '@mui/styles';
import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import { LayoutProps } from '../../types/route.types';
import { SideNavMenu } from '../shared/sideNavMenu/side-nav-menu';

export const Layout = ({ route }: LayoutProps): ReactElement => {
	const classes = useStyles();

	return (
		<div className={classes.layout}>
			<SideNavMenu />
			<main className={classes.content}>
				<Routes>
					{route.map((r, i) => (
						<Route path={r.path} element={r.element} key={i} />
					))}
				</Routes>
			</main>
		</div>
	);
};

const useStyles = makeStyles(() => ({
	layout: {
		height: '100%',
		width: '100%',
		display: 'flex',
		position: 'absolute',
	},
	content: {
		padding: '32px 30px 16px 32px !important',
		width: '100%',
	},
}));
