import * as React from 'react';
import { DialogType } from '../../../types/common.types';
import { makeStyles } from '@mui/styles';
import { colors, theme } from '../../../theme';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const testid = 'dialog';

const color = colors();
const themes = theme().typography;

export const CustomDialog = ({
	open,
	title,
	component,
	yesButtonText,
	noButtonText,
	yesButtonDisable,
	onCloseCallback,
	onNoClickCallback,
	onYesClickCallback,
}: DialogType) => {
	const classes = useStyles();

	return (
		<Dialog open={open} maxWidth="sm" fullWidth className={classes.dialog} data-testid={testid}>
			<DialogTitle {...themes.h5} data-testid={`${testid}-title`}>
				{title}
			</DialogTitle>
			<IconButton
				onClick={() => onCloseCallback && onCloseCallback(false)}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: color.neutral[7],
				}}
				data-testid={`${testid}-close`}
			>
				<CloseIcon className={classes.closeIcon} />
			</IconButton>
			<DialogContent data-testid={`${testid}-content`}>{component}</DialogContent>
			<DialogActions data-testid={`${testid}-actions`}>
				{noButtonText && (
					<Button
						id="no-button"
						onClick={() => onNoClickCallback && onNoClickCallback()}
						data-testid={`${testid}-action-no`}
					>
						{noButtonText}
					</Button>
				)}
				{yesButtonText && (
					<Button
						id="yes-button"
						disabled={yesButtonDisable}
						onClick={() => onYesClickCallback && onYesClickCallback()}
						sx={{
							color: yesButtonDisable ? color.text.disabled : color.text.light,
							backgroundColor: yesButtonDisable ? color.neutral[4] : color.primary[100],
							border: `1px solid ${yesButtonDisable ? color.neutral[4] : color.primary[100]} !important`,
							'&:hover': {
								backgroundColor: yesButtonDisable ? color.neutral[4] : color.primary[100],
							},
						}}
						data-testid={`${testid}-action-yes`}
					>
						{yesButtonText}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

const useStyles = makeStyles(() => ({
	dialog: {
		'& .MuiPaper-root': {
			width: '410px',
		},
		'& .MuiButton-root': {
			height: '28px',
			textTransform: 'none',
			border: `1px solid ${color.primary[100]}`,
			...themes.bodyMedium,
		},
		'& #no-button': {
			color: color.primary[100],
		},
	},
	closeIcon: {
		width: '16px !important',
		height: '16px !important',
		color: color.primary[100],
	},
}));
